<script>
export default {
  name: 'ShapeOptions',
  computed: {
    activeShape: {
      get () { return this.$store.state.Tools.shape.active },
      set (val) { this.$store.commit('Tools/setActiveShape', val) }
    },
    strokeColor: {
      get () { return this.$store.state.Tools.shape.strokeColor },
      set (val) { 
        if (this.$store.state.Tableau.selection) {
          const typo = this.$store.state.Tableau.selection.get('stroke')
          if (val !== typo && this.$store.state.Tableau.selection?.custom.type !== 'text') {
            this.$store.state.Tableau.selection.set('stroke', val)
            this.$store.state.Tableau.selection.isEditing = false
            this.$store.state.App.activeCanvas.renderAll()
          }
        }
        this.$store.commit('Tools/setShapeStrokeColor', val) 
      }
    },
    fillColor: {
      get () { return this.$store.state.Tools.shape.fillColor },
      set (val) { 
        if (this.$store.state.Tableau.selection) {
          const typo = this.$store.state.Tableau.selection.get('fill')
          if (val !== typo && this.$store.state.Tableau.selection?.custom.type !== 'text') {
            this.$store.state.Tableau.selection.set('fill', val)
            this.$store.state.Tableau.selection.isEditing = false
            this.$store.state.App.activeCanvas.renderAll()
          }
        }
        this.$store.commit('Tools/setShapeFillColor', val) 
      }
    },
    strokeWidth: {
      get () { return this.$store.state.Tools.shape.strokeWidth },
      set (val) {
        if (this.$store.state.Tableau.selection) {
          const strokeWidth = this.$store.state.Tableau.selection.get('strokeWidth')
          if (val !== strokeWidth && this.$store.state.Tableau.selection?.custom.type !== 'text') {
            this.$store.state.Tableau.selection.set('strokeWidth', parseInt(val))
            this.$store.state.Tableau.selection.isEditing = false
            this.$store.state.App.activeCanvas.renderAll()
          }
        }
        this.$store.commit('Tools/setShapeStrokeWidth', val) }
    }
  },
  data () {
    return {
      shapeList: ['circle', 'rectangle', 'triangle']
    }
  },
  methods: {
    selectShape (shape) {
      this.activeShape = shape
    }
  }
}
</script>

<template lang="pug">
.tool-index.text-options
  .tool-section
    .tool-option.vertical
      .tool-option--title
        | Forme
      .tool-option--control
        .multi-options__wrapper
          button(@click='selectShape("circle")', :class='{"active-option": activeShape === "circle"}')
            font-awesome-icon(:icon="['fas', 'circle']", style='font-size: 16px')
          button(@click='selectShape("rectangle")', :class='{"active-option": activeShape === "rectangle"}')
            font-awesome-icon(:icon="['fas', 'rectangle-landscape']", style='font-size: 16px')
          button(@click='selectShape("triangle")', :class='{"active-option": activeShape === "triangle"}')
            font-awesome-icon(:icon="['fas', 'triangle']", style='font-size: 16px')
        //- select(v-model='activeShape', name='activeShape')
          option(v-for='(shape, index) in shapeList', :key='index', :value='shape') {{shape}}
    section.tool-option.vertical()
      label.tool-option--title
        | Couleur trait
      div.tool-option--control
        div.option-wrapper(style='display: flex;')
          input.hex-input(v-model='strokeColor', type='text', maxlength='7')
          label.color-selector()
            input#favcolor(v-model='strokeColor', type='color', style='opacity:0;')
            div.grid-color-tag(:style='{backgroundColor: strokeColor}')
    section.tool-option.vertical()
      label.tool-option--title
        | Couleur remplissage
      div.tool-option--control
        div.option-wrapper(style='display: flex;')
          input.hex-input(v-model='fillColor', type='text', maxlength='7')
          label.color-selector()
            input#favcolor(v-model='fillColor', type='color', style='opacity:0;')
            div.grid-color-tag(:style='{backgroundColor: fillColor}')
    section.tool-option.vertical
      label.tool-option--title
        | Épaisseur du trait
      div.tool-option--control.slider()
        input#gridOpacity.slider-input(v-model='strokeWidth', type='range', min='1', max='200', step='1')
        input.value-input(type='number', v-model='strokeWidth', min='0', max='200')
        span.value-input-scale px
</template>

<style lang='sass'>

</style>
