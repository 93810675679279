<script>
export default {
  name: 'MathOptions',
  computed: {
    selectedFontSize: {
      get () {
        return this.$store.state.Tools.text.fontSize
      },
      set (val) {
        this.$store.commit('Tools/setFontSize', val)
      }
    },
    showMathPreview: {
      get () {return this.$store.state.Tools.math.showMathPreview},
      set (val) {this.$store.commit('Tools/setShowMathPreview', val)}
    }
  },
  data () {
    return {
      fontSizes: [
        10, 12, 14, 16, 18, 20, 24, 30, 40, 64, 80, 100, 120
      ]
    }
  },
}
</script>
<template lang="pug">
.tool-index.text-options
  .tool-section
    section.tool-option
      label.tool-option--title
        | Taille de caractère
      .tool-option--control
        select(v-model='selectedFontSize', style='width: 100%')
          option(v-for='(option, index) in fontSizes', :value='option') {{option}}
    section.tool-option.vertical
      div Veuillez cliquer là où vous désirez insérer votre fonction mathématique.
</template>

<style lang='sass'>

</style>
