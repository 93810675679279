<script>
import mapStateTwoWay from '@/mixins/mapStateTwoWay'

export default {
  name: 'DrawOptions',
  computed: {
    ...mapStateTwoWay([
      { n: 'Tableau', k: 'canvasZoom', m: 'setCanvasZoom' },
      { n: 'Tools', k: 'isDrawToolHoverShowPreview', m: 'setIsDrawToolHoverShowPreviewt' }
    ]),
    drawMode: {
      get () { return this.$store.state.Tools.draw.drawMode },
      set (value) { this.$store.commit('Tools/setDrawMode', value) }
    },
    brushColor: {
      get () { return this.$store.state.Tools.draw.brushColor },
      set (value) { this.$store.commit('Tools/setBrushColor', value) }
    },
    lineWidth: {
      get () { return this.$store.state.Tools.draw.lineWidth },
      set (value) { this.$store.commit('Tools/setLineWidth', value) }
    },
    lineColor: {
      get () { return this.$store.state.Tools.draw.lineColor },
      set (value) { this.$store.commit('Tools/setLineColor', value) }
    },
    shadowWidth: {
      get () { return this.$store.state.Tools.draw.shadowWidth },
      set (value) { this.$store.commit('Tools/setShadowWidth', value) }
    },
    shadowColor: {
      get () { return this.$store.state.Tools.draw.shadowColor },
      set (value) { this.$store.commit('Tools/setShadowColor', value) }
    },
    shadowOffset: {
      get () { return this.$store.state.Tools.draw.shadowOffset },
      set (value) { this.$store.commit('Tools/setShadowOffset', value) }
    },
    previewStyle () {
      return {
        width: `${this.lineWidth * this.canvasZoom}px`,
        height: `${this.lineWidth * this.canvasZoom}px`,
        borderRadius: `${this.lineWidth * this.canvasZoom}px`,
        backgroundColor: `${this.brushColor}`
      }
    }
  },
  methods: {
  }
}
</script>

<template lang="pug">
.tool-index.draw-options(@mouseover="isDrawToolHoverShowPreview = true" @mouseleave="isDrawToolHoverShowPreview = false")
  .tool-section
    //- .tool-option()
    section.tool-option.vertical()
      label.tool-option--title
        | Couleur
      div.tool-option--control
        div.option-wrapper(style='display: flex;')
          input.hex-input(v-model='brushColor', type='text', maxlength='7')
          label.color-selector()
            input#favcolor(v-model='brushColor', type='color', style='opacity:0;')
            div.grid-color-tag(:style='{backgroundColor: brushColor}')
    section.tool-option.vertical
      label.tool-option--title
        | Épaisseur du trait
      div.tool-option--control.slider
        input#gridOpacity.slider(v-model='lineWidth', type='range', min='1', max='200', step='1')
        input.value-input(type='number', v-model='lineWidth', min='0', max='200')
        span.value-input-scale px


    //- section.tool-option()
      label.tool-option--title
        | Largeur d'ombre
      div.tool-option--control
        input#gridOpacity.slider(v-model='shadowWidth', type='range', min='0', max='1', step='0.01')
    //- section.tool-option()
      label.tool-option--title
        | Distance d'ombre
      div.tool-option--control
        input#gridOpacity.slider(v-model='shadowOffset', type='range', min='0', max='1', step='0.01')
    //- div.option-wrapper(style='display: flex;')
    //-   input.hex-input(v-model='gridColor', type='text', maxlength='7')
    //-   label.color-selector()
    //-     input#favcolor(v-model='gridColor', type='color', style='opacity:0;')
    //-     div.grid-color-tag()
</template>

<style lang='sass'>
  // @import 'src/assets/styles/components/tableau/_toolProperties.sass'
  .color-selector
    margin-left: 10px
    position: relative
    cursor: pointer
    input
      position: absolute
    .grid-color-tag, .bg-color-tag, .txtbg-color-tag, .arrow-color-tag
      width: 20px
      height: 20px
      border: solid 2px var(--secondary)
      border-radius: 15px
    .grid-color-tag
      background-color: var(--grid-color)
    .bg-color-tag
      background-color: var(--bg-color)
    .txtbg-color-tag
      background-color: var(--txtbg-color)
    .arrow-color-tag
      background-color: var(--arrow-color)
</style>
