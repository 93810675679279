<script>
import vue2Dropzone from 'vue2-dropzone'
import 'vue2-dropzone/dist/vue2Dropzone.min.css'

export default {
  name: 'ImagePopinUpload',
  components: { vue2Dropzone },
  data: () => ({
    fileObject: null,
    dropzoneOptions: {
      url: 'https://api.dokoma.com/api/v1/commun/drop_zones.json',
      method: "post",
      thumbnailWidth: 150,
      maxFilesize: 10,
      maxFiles: 1,
      acceptedFiles: '.jpg,.jpeg,.png',
      headers: {},
      paramName: "drop_zone[fichier]"
    },
    showSuccess: false,
    showError: false,
    errorMessage: ''
  }),
  mounted () {
    const token = this.$cookies.get('ClassDokomaToken')
    this.dropzoneOptions.headers.Authorization = 'Bearer ' + token
  },
  methods: {
    showSuccessConfirmation (file, response) {
      this.showSuccess = true
      if (response?.tache) {
        this.$store.dispatch('Tools/startImageTache', response.tache)
      }
    },
    showErrorConfirmation (file, message) {
      this.errorMessage = message
      this.showError = true
    },
    sending (file, xhr, formData) {
      this.showSuccess = false
      this.showError = false
      this.errorMessage = ''
      formData.append("drop_zone[nature]", "image")
    }
  }
}
</script>

<template lang="pug">
.image-popin-upload
  h3 Téléverser une nouvelle image
  form(@submit.prevent="sendFile")
  vue2-dropzone(
    id="dropzone"
    :useCustomSlot="true"
    :options="dropzoneOptions"
    @vdropzone-sending="sending"
    @vdropzone-success="showSuccessConfirmation"
    @vdropzone-error="showErrorConfirmation"
  )
    h5 Cliquez ici pour téléverser une image de votre ordinateur

  //- Confirmation messages
  .confirmation.success(v-if="showSuccess")
    font-awesome-icon.icon(:icon="['fad', 'check']")
    span Image téléversée avec succès. 
    | Celle-ci devrait apparaître dans quelques instants....
  .confirmation.error(v-if="showError")
    font-awesome-icon.icon(:icon="['fad', 'check']")
    span Une erreur est survenue lors de l'envoi du fichier
    span(v-if="errorMessage") : {{ errorMessage }}
</template>

<style lang="sass" scoped>
.image-popin-upload
  width: 250px
  padding: 0 15px 0 40px
  flex: 0 0 250px
  height: 100%
  border-left: 1px solid grey
  h3
    text-align: center
  #dropzone
    border-radius: 12px
    max-height: 220px
    overflow: hidden
    margin-bottom: 10px
  .confirmation
    font-size: 14px
    margin: 12px 0
    padding: 6px 10px
    border-radius: 12px
    background-color: #fff
    .icon
      margin-right: 6px
    &.success
      color: var(--success)
    &.error
      color: var(--error)
  @media (max-width: $small)
    padding: 0 25px
    width: 200px
    border-left: none
  @media (max-height: $small)
    border-left: none
</style>