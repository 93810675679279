<script>
export default {
  name: 'ImagePopinImgPreview',
  props: {
    img: { type: Object, required: true },
    selected: { type: Boolean, required: true }
  },
  methods: {
    hideIcon () {
      const idToHide = this.img?.id
      const hideImagesCookieContent = this.$cookies.get('HideImagesFromGallery')
      const hiddenList = hideImagesCookieContent?.l ?? []
      this.$cookies.set('HideImagesFromGallery', { l: [...hiddenList, idToHide] }, 1000 * 60 * 60 * 24 * 365, '/', null)
      this.$emit('refresh')
    }
  }
}
</script>

<template lang="pug">
.gallery-preview(:class="{ selected }" @click="$emit('click')")
  font-awesome-icon.hide(@click="hideIcon" :icon="['fad', 'trash']")
  .preview-img(:style="{ backgroundImage: `url(${img.thumbnail})` }")
  .preview-img-size {{ img.largueur }}x{{img.hauteur}}
</template>

<style lang="sass" scoped>
.gallery-preview
  position: relative
  width: 65px
  height: 65px
  border: 2px solid #d0d0d0
  border-radius: 10px
  background-color: #fff
  font-size: 10px
  padding: 10px
  cursor: pointer
  .hide
    position: absolute
    top: 0
    right: 0
    font-size: 14px
  .preview-img
    width: 100%
    height: 100%
    background-size: contain
    background-repeat: no-repeat
    background-position: center center
  .preview-img-size
    position: absolute
    background-color: #d0d0d0
    left: 50%
    transform: translateX(-50%)
    padding: 2px 4px
    border-radius: 6px
  &.selected
    border-color: #a1cee6
    .preview-img-size
      background-color: #a1cee6
</style>
