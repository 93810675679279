<script>
import ToolsList from '@/mixins/toolsList.js'
import mapStateTwoWay from '@/mixins/mapStateTwoWay'

export default {
  name: 'Tools',
  mixins: [
    ToolsList
  ],
  computed: {
    ...mapStateTwoWay([
      { n: 'Tools', k: 'activeTool', m: 'setActiveTool' },
      { n: 'App', k: 'showHelpModal', m: 'setShowHelpModal' },
    ]),
  }
}
</script>

<template lang="pug">
.tools-index
  perfect-scrollbar.tools__scroller()
    .tools__wrapper()
      template(v-for='(tool, index) in toolset')
        button.tool(
          @click='toggleActiveTool(tool)'
          v-tooltip.right="{ content: tool.title, delay: { show: 120, hide: 100 }}"
          :class='{"active-tool" : (activeTool && activeTool.value === tool.value)}'
          :disabled='tool.disabled'
          v-ripple
        )
          .button-content
            font-awesome-icon(:icon="[tool.icon.type, tool.icon.name]")
      div.buttons-spacer
      button.help-button(v-ripple, @click='showHelpModal = !showHelpModal', style='margin-top: auto')
        font-awesome-icon(:icon="['fas', 'question']", style='font-size: 22px')
  //- span Selection outils ici
</template>
<style lang='sass'>
  @import 'src/assets/styles/layout/_tools.sass'

</style>
