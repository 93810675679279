<script>
import youtubeAndVimeoHelpers from '@/mixins/youtubeAndVimeoHelpers'

export default {
  name: 'VideoPlayer',
  mixins: [youtubeAndVimeoHelpers],
  computed: {
    activeVideoLink () {
      return this.$store.getters['Tableau/activeLink']
    },
    isYtVideoLinkCorrect () {
      return this.getYouTubeVideoIdFromUrl(this.activeVideoLink) !== null
    },
    embedVideoLink () {
      if (this.isYtVideoLinkCorrect) {
        return this.generateYouTubeUrl(this.getYouTubeVideoIdFromUrl(this.activeVideoLink))
      } else {
        return ''
      }
    }
  }
}
</script>

<template lang="pug">
transition(name="pop")
  .video-player-container(v-if="activeVideoLink")
    .video-player
      iframe.video-player-youtube(
        v-if="isYtVideoLinkCorrect"
        width="1920"
        height="1080"
        :src="embedVideoLink"
        title="YouTube video player"
        frameborder="0"
        allowfullscreen
        allow="accelerometer; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
      )
</template>

<style lang="sass" scoped>
.video-player-container
  position: absolute
  z-index: 8
  padding: 200px 80px
  top: 0
  bottom: 0
  left: 0
  right: 0
  display: flex
  justify-content: center
  align-items: center
  pointer-events: none
  .video-player
    pointer-events: auto
    display: flex
    justify-content: center
    align-items: center
    max-height: 100%
    max-width: 100%
    aspect-ratio: 16 / 9
    padding: 2px
    border: solid 4px var(--secondary)
    border-radius: 15px
    overflow: hidden
    iframe
      max-width: 100%
      max-height: 100%
      border-radius: 10px
</style>
