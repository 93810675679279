<script>
import toolsList from '@/mixins/toolsList'

export default {
  name: 'DesmosModal',
  mixins: [toolsList],
  data () {
    return {
      mathField: null,
      selectedTaille: { titre: 'Moyenne', value: { height: 200, width: 200 } },
      items: [
        { titre: 'Petite', value: { height: 100, width: 100 } },
        { titre: 'Moyenne', value: { height: 200, width: 200 } },
        { titre: 'Grande', value: { height: 400, width: 400 } }
      ]
    }
  },
  computed: {
    showDesmosPreview: {
      get () {
        return this.$store.state.Tools.showDesmosPreview},
      set (val) {this.$store.commit('Tools/setShowDesmosPreview', val)}
    }
  },
  watch: {
    'showDesmosPreview' (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.createDesmosElement()
        }, 200)
      }
    }
  },
  methods: {
    createDesmosElement () {
      this.calculator = window.Desmos.GraphingCalculator(document.getElementById('desmoswrapper'))
      this.$store.state.Tools.desmosToInsert?.desmosExpressions?.forEach(element => {
        this.calculator.setExpression(element)
      });
    },
    toggleDesmos () {
      this.$store.commit('Tools/setShowDesmosPreview', false)
    },
    insertDesmos () {
      this.calculator.asyncScreenshot({
        width: this.selectedTaille.value.width,
        height: this.selectedTaille.value.height,
        format: 'svg'
      }, (data) => {
        this.$store.commit('Tools/setDesmosSvg', data)
        this.$store.commit('Tools/setDesmosExpressions', this.calculator.getExpressions())
      })
      this.toggleDesmos()
    }
  }
}
</script>

<template lang="pug">

.modal-index-desmos(v-if='showDesmosPreview')
  a.modal-overlay-desmos(@click='toggleDesmos', :class='{"visible-overlay" : showDesmosPreview}')
  .modal-desmos
    div.modal-scroller-desmos(id='pages-scroller')
      .modal-header-desmos
        span Desmos
        button.close-button-desmos(@click='toggleDesmos')
          font-awesome-icon(:icon="['fas', 'times']", style='font-size: 24px')
      .modal-content__wrapper-desmos
        div(id='desmoswrapper' style="height: 400px;")
      .modal-actions__wrapper-desmos(v-if="!$store.state.App.viewerMode")
        select(
          v-model="selectedTaille"
          :items="items"
          return-object
        )
          option(v-for='(option, i) in items', :value='option') {{option.titre}}
        button(@click='insertDesmos', large) Insérer
</template>

<style lang='sass'>
  @import 'src/assets/styles/base/_modalDesmos.sass'
</style>
