<script>
export default {
  name: 'ArrowOptionsPreview',
  computed: {
    start () {
      return this.$store.state.Tools.arrow.start
    },
    end () {
      return this.$store.state.Tools.arrow.end
    }
  }
}
</script>

<template lang="pug">
.arrow-preview-container
  .arrow-preview
    .arrow-preview-start
      .circle(v-show="start === 'circle'")
      font-awesome-icon.caret(:icon="['fas', 'caret-left']" v-show="start === 'caret'")
    .arrow-preview-body
    .arrow-preview-end
      .circle(v-show="end === 'circle'")
      font-awesome-icon.caret(:icon="['fas', 'caret-right']" v-show="end === 'caret'")
</template>

<style lang='sass' scoped>
.arrow-preview-container
  width: 100%
  height: 45px
  display: flex
  align-items: center
  justify-content: center
  margin: 15px 0
  .arrow-preview
    position: relative
    .arrow-preview-body
      width: 70px
      height: 6px
      background-color: black
    .arrow-preview-start, .arrow-preview-end
      position: absolute
      left: 50%
      top: 50%
    .circle
      height: 15px
      width: 15px
      border-radius: 15px
      background-color: black
    .caret
      font-size: 35px
    .arrow-preview-start
      .circle, .caret
        transform: translate(-47px, -50%)
    .arrow-preview-end
      .circle, .caret
        transform: translate(34px, -50%)
</style>
