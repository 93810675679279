<script>
import draggable from 'vuedraggable'
import MiniatureData from '@/components/layout/miniatureData'
import tableauControls from '@/mixins/tableauControls'
import TableauPages from '@/mixins/TableauPages'

export default {
  name: 'PageMenu',
  mixins: [tableauControls, TableauPages],
  components: {
    draggable,
    MiniatureData
  },
  data () {
    return {
      pagesCounter: 1,
      drag: false
    }
  },
  methods: {
    togglePagesMenu () {
      this.showPages = !this.showPages
      if (this.showPages) {
        this.$store.commit('App/updateCanvasAvailable', this.createCanvasObject())
      }
    }
  }
}
</script>

<template lang="pug">
.pages-menu-index(:class='{"temporary-menu" : $store.state.App.windowSize.width < 1200, "active-menu" : showPages}')
  a.pages-overlay(@click='togglePagesMenu', :class='{"visible-overlay" : showPages}', v-if='$store.state.App.windowSize.width < 1200')
  .pages-menu-content(:class='{"visible-menu" : showPages}')
    .pages-list__header
      span Pages
      button.close-button(@click='togglePagesMenu')
        font-awesome-icon(:icon="['fas', 'times']", style='font-size: 22px')
    perfect-scrollbar.pages-menu-content__scroller(id='pages-scroller')
      .pages-list__wrapper(v-if='showPages')
        draggable(
          :list="canvasAvailable"
          class="list-group"
          handle=".drag-handle"
          group="a",
          ghost-class='ghost'
          animation='200'
          @start="drag = true"
          @end="drag = false"
        )
          transition-group(type="transition", :name="!drag ? 'flip-list' : null", tag='ul')
            li.item.page-preview.list-group-item(
              v-for='(page,index) in canvasAvailable'
              v-ripple='{color: "grey", duration: "1s"}'
              @click='goToPage(page.id)'
              :class='{"active-page" : activePage === (page.id)}'
              :key='page.id'
            )
              miniature-data(:data='page')
              div.page-index Page {{index + 1}}
              button.delete-button(@click.stop='deletePage(page)', v-if='canvasAvailable.length > 1')
                font-awesome-icon(:icon="['fad', 'trash-alt']", style='font-size: 12px')
              .drag-handle(v-if='canvasAvailable.length > 1')
                font-awesome-icon(:icon="['fas', 'grip-vertical']", style='font-size: 12px')

        div.page-preview.new-page(@click='addNewPage()', v-ripple='{color: "grey", duration: "1s", opacity: "0.4"}')
          font-awesome-icon(:icon="['fas', 'plus']", style='font-size: 22px')
          div() Ajouter une page
</template>

<style lang='sass'>
  @import 'src/assets/styles/layout/_pagesMenu.sass'
  .flip-list-move
    transition: transform 0.5s
  .no-move
    transition: transform 0s
</style>
