<script>
export default {
  name: 'ZoomIndicator',
  computed: {
    zoomText () {
      return Math.round(this.$store.state.Tableau.canvasZoom * 100) + '%'
    }
  }
}
</script>

<template lang="pug">
.zoom-indicator-container
  .zoom-indicator(v-tooltip.top="{ content: 'Niveau de zoom', offset: '5'}") {{ zoomText }}
</template>

<style lang="sass" scoped>
.zoom-indicator-container
  min-width: 30px
</style>