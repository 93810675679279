<script>
import Tools from '@/components/layout/tools.vue'
import HeaderPage from '@/components/layout/header.vue'
import PagesMenu from '@/components/layout/pagesMenu.vue'
import Tableau from '@/components/tableau.vue'
import HelpModal from '@/components/shared/helpModal.vue'
import MathModal from '@/components/shared/mathModal.vue'
import DesmosModal from '@/components/shared/desmosModal.vue'
import Audio from '@/components/tableau/audio/audioToLire.vue'
import AudioModal from '@/components/shared/audioModal.vue'

export default {
  name: 'Home',
  components: {
    HeaderPage,
    PagesMenu,
    Tools,
    Tableau,
    HelpModal,
    MathModal,
    DesmosModal,
    AudioModal,
    Audio
  },
  props: {
  },
  methods: {
  }
}
</script>

<template lang="pug">
.home-index
  help-modal
  math-modal
  desmos-modal
  audio-modal
  Audio
  header-page
  div.main-content
    tools(v-if='!$store.state.App.viewerMode')
    tableau
    pages-menu
</template>

<style lang='sass'>
  @import 'src/assets/styles/pages/_home.sass'

</style>
