<script>
import LinkPopinBow from '@/components/tableau/Links/LinkPopinBow.vue'
import LinkPopinTarget from '@/components/tableau/Links/LinkPopinTarget.vue'
import toolsList from '@/mixins/toolsList'

export default {
  name: 'LinkPopin',
  mixins: [toolsList],
  components: { LinkPopinBow, LinkPopinTarget },
  data: () => ({
    selected: null,
  }),
  methods: {
    closePopin () {
      this.toggleActiveToolFromValue('pan')
    },
    clickOutside (e) {
      if (e.target === e.currentTarget) {
        this.closePopin()
      }
    },
  }
}
</script>

<template lang="pug">
.link-popin-container(@click="clickOutside")
  .link-popin
    transition(name="link-container")
      link-popin-bow(
        :selected="selected === 'bow'"
        v-show="!selected || selected === 'bow'"
        @select="selected = $event"
      )
    transition(name="link-container")
      link-popin-target(
        :selected="selected === 'target'"
        v-show="!selected || selected === 'target'"
        @select="selected = $event"
      )
</template>

<style lang="sass" scoped>
.link-popin-container
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(0,0,0,0.5)
  z-index: 100
  .link-popin
    position: relative
    width: 320px
    height: 400px
    background-color: #fff
    border-radius: 18px
    overflow: hidden
    font-size: 22px
    background-color: var(--primary)
    box-shadow: 8px 8px 18px rgba(0,0,0,0.3)
    &::v-deep .container
      height: 50%
      max-height: 50%
      transition: height 0.8s ease, max-height 0.8s ease, padding 0.8s ease
      padding: 25px 40px
      box-sizing: border-box
      &.selected
        height: 100%
        max-height: 100%
      .selection
        position: relative
        display: flex
        align-items: center
        justify-content: space-between
        height: 100%
        gap: 40px
        text-align: center
        cursor: pointer
        transform: translateY(-15px)
        margin-bottom: 15px
        .icon
          transition: 0.2s ease
          font-size: 35px
        .subtitle
          position: absolute
          width: 100%
          font-size: 12px
          bottom: -10%
          left: 50%
          transform: translateX(-50%)
      &:hover .selection .icon
        transform: scale(1.5)
      .adding
        display: flex
        align-items: center
        flex-direction: column
        text-align: center
        .icon
          font-size: 75px
          margin-top: 25px
        .btn
          opacity: 1
          padding: 6px 18px
          border-radius: 20px
          cursor: pointer
          transition: padding 0.2s ease
          margin: 25px 0 15px 0
          &:hover
            padding: 6px 20px
      .back
        position: absolute
        bottom: 20px
        margin: 0
        font-size: 14px
        cursor: pointer
</style>
