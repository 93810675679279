<script>
import mapStateTwoWay from '@/mixins/mapStateTwoWay'
import tableauControls from '@/mixins/tableauControls'
import TableauPages from '@/mixins/TableauPages'

export default {
  name: 'Controls',
  mixins: [TableauPages, tableauControls],
  props: {
  },
  data () {
    return {
      gridOptions: false,
      gridTypes: [
        { title: 'Grille 1', value: 'grid1', class: 'pattern-grid-sm' },
        { title: 'Grille 2', value: 'grid2', class: 'pattern-grid-md' },
        { title: 'Grille 3', value: 'grid3', class: 'pattern-grid-lg' },
        { title: 'Grille 4', value: 'grid4', class: 'pattern-grid-xl' },
        { title: 'Pointillé 1', value: 'points1', class: 'pattern-dots-sm' },
        { title: 'Pointillé 2', value: 'points2', class: 'pattern-dots-md' },
        { title: 'Pointillé 3', value: 'points3', class: 'pattern-dots-lg' },
        { title: 'Pointillé 4', value: 'points4', class: 'pattern-dots-xl' }
      ]
    }
  },
  watch: {
    gridOpacity: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          document.documentElement.style.setProperty('--grid-opacity', this.gridOpacity)
        }
      }
    },
    gridColor: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          document.documentElement.style.setProperty('--grid-color', this.gridColor)
        }
      }
    }
  },
  computed: {
    ...mapStateTwoWay([
      { n: 'Tableau', k: 'activeGrid', m: 'setActiveGrid' },
      { n: 'Tableau', k: 'gridOpacity', m: 'setGridOpacity' },
      { n: 'Tableau', k: 'gridColor', m: 'setGridColor' },
      { n: 'Tableau', k: 'tableauBgColor', m: 'setTableauBgColor' },
      { n: 'App', k: 'showPages', m: 'setShowPages' },
    ]),
    isValide () {
      return this.$store.state.Tableau?.elementSelected ? true : false
    }
  },
  methods: {
    selectGrid (grid) {
      if (grid) {
        this.activeGrid = grid
      } else {
        this.activeGrid = null
      }
    },
    togglePages () {
      this.showPages = !this.showPages
    }
  }
}
</script>

<template lang="pug">
.controls-index
  .controls__wrapper
    template(v-if='!$store.state.App.viewerMode')
      button.control-button(@click='$emit("toBack")',v-ripple, v-tooltip.top="{ content: 'déplacer en arrière', delay: { show: 500, hide: 100 }, offset: '5'}", :disabled='!isValide')
        font-awesome-icon(:icon="['fad', 'send-backward']", style='font-size: 24px')
      button.control-button(v-ripple, v-tooltip.top="{ content: 'déplacer en avant', delay: { show: 500, hide: 100 }, offset: '5'}", :disabled='!isValide')
        font-awesome-icon(@click='$emit("toFront")',:icon="['fad', 'bring-forward']", style='font-size: 24px')
      button.control-button(v-ripple, v-tooltip.top="{ content: 'Agrandir', delay: { show: 500, hide: 100 }, offset: '5'}", disabled)
        font-awesome-icon(:icon="['fas', 'undo']", style='font-size: 24px')
      button.control-button(v-ripple, v-tooltip.top="{ content: 'Réduire', delay: { show: 500, hide: 100 }, offset: '5'}", disabled)
        font-awesome-icon(:icon="['fas', 'redo']", style='font-size: 24px')
    button.control-button(@click='$emit("zoomIn")', v-ripple, v-tooltip.top="{ content: 'Agrandir', delay: { show: 500, hide: 100 }, offset: '5'}")
      font-awesome-icon(:icon="['fad', 'search-plus']", style='font-size: 24px')
    button.control-button(@click='$emit("zoomOut")', v-ripple, v-tooltip.top="{ content: 'Réduire', delay: { show: 500, hide: 100 }, offset: '5'}")
      font-awesome-icon(:icon="['fad', 'search-minus']", style='font-size: 24px')
    button.control-button(@click='$emit("recenter")', v-ripple, v-tooltip.top="{ content: 'Recentrer le contenu', delay: { show: 500, hide: 100 }, offset: '5'}")
      font-awesome-icon(:icon="['fad', 'location']", style='font-size: 24px')
    v-popover.top-end(offset='16', :class='["grid-menu"]', v-if='!$store.state.App.viewerMode')
      button.control-button.grid-button()
        div.active-grid
          div(v-if='activeGrid', :class='activeGrid.class', style='width:100%; height:100%;')
          //- pre {{selectedGrid.value}}
        //- font-awesome-icon(:icon="['fad', 'search-plus']", style='font-size: 24px')
      template(slot='popover')
        .popover-custom__wrapper
          //- pre {{tableauBgColor}}
          section
            div.subtitle Couleur de fond
            div.option-wrapper(style='display: flex;')
              input.hex-input(v-model='tableauBgColor', type='text', maxlength='7')
              label.color-selector()
                input#favcolor(v-model='tableauBgColor', type='color', style='opacity:0;')
                div.bg-color-tag()

          hr
          section
            div.subtitle Grille
            div.grid-selector
              template(v-for='(grid, index) in gridTypes')
                button.grid-element(@click='selectGrid(grid)', :class='{"active-element" : (activeGrid && activeGrid.value === grid.value)}')
                  .grid-image(:class='grid.class')
              button.grid-element(@click='selectGrid()', :class='{"active-element" : !activeGrid}')
                font-awesome-icon(:icon="['fas', 'times']", style='font-size: 24px; color: red;')
          hr
          section(:class='{"disabled-section" : !activeGrid}')
            div.subtitle Couleur de grille
            div.option-wrapper(style='display: flex;')
              input.hex-input(v-model='gridColor', type='text', maxlength='7')
              label.color-selector()
                input#favcolor(v-model='gridColor', type='color', style='opacity:0;')
                div.grid-color-tag()
          hr
          section(:class='{"disabled-section" : !activeGrid}')
            div.subtitle Opacité grille
            input#gridOpacity.slider(v-model='gridOpacity', type='range', min='0', max='1', step='0.01')
  button.pages-button(v-if='!$store.state.App.viewerMode', @click='addNewPage', v-ripple, v-tooltip.top-end="{ content: 'Ajouter une nouvelle page', offset: '5'}")
    font-awesome-icon(:icon="['fad', 'file-plus']", style='font-size: 26px')
</template>

<style lang='sass'>
  @import 'src/assets/styles/components/tableau/_controls.sass'
  .grid-selector
    display: flex
    flex-wrap: wrap
    .grid-element
      width: 36px
      height: 36px
      padding: 0px
      border-radius: 10px
      border: solid 2px var(--secondary)
      background-color: white
      margin: 5px
      outline: none
      &:focus
        box-shadow: $shadow-sm
      &.active-element
        border: solid 2px var(--error)
      .grid-image
        background-color: var(--bg-color)
        color: var(--grid-color)
        width: 100%
        height: 100%
        border-radius: 8px
        background-position: 40% 50%
  .popover-custom__wrapper
    border-radius: 8px
    padding: 10px
    background-color: var(--primary)
    box-shadow: $shadow-lg
    width: 184px
    section
      &.disabled-section
        opacity: 0.5
        pointer-events: none
        filter: grayscale(1)
      .subtitle
        font-size: 12px
      .option-wrapper
        display: flex
        align-items: center
        justify-content: flex-end
        .hex-input
          width: 100%
    .slider
      width: 100%
    .color-selector
      margin-left: 10px
      position: relative
      cursor: pointer
      input
        position: absolute
      .grid-color-tag, .bg-color-tag
        width: 20px
        height: 20px
        border: solid 2px var(--secondary)
        border-radius: 15px
      .grid-color-tag
        background-color: var(--grid-color)
      .bg-color-tag
        background-color: var(--bg-color)

</style>
