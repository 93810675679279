<script>
import tableauControls from '@/mixins/tableauControls'
import toolsList from '@/mixins/toolsList'
import mapStateTwoWay from '@/mixins/mapStateTwoWay'

export default {
  name: 'Header',
  mixins: [tableauControls, toolsList],
  data () {
    return {
      editName: false,
      lastSaveLabel: null
    }
  },
  computed: {
    ...mapStateTwoWay([
      { n: 'App', k: 'showNotification', m: 'setShowNotification' },
      { n: 'App', k: 'viewerMode', m: 'setViewerMode' },
      { n: 'App', k: 'defaultName', m: 'setDefaultName' },
    ]),
    isProprietaire () {
      let temp = false
      if (!this.$store.state.User.profile) {
        temp = false
      } else if (this.$store.state.User.profile && this.$store.state.App.dataRef && this.$store.state.App.dataRef.proprietaire && this.$store.state.App.dataRef.proprietaire.id === this.$store.state.User.profile.id) {
        temp = true
      } else if (this.$store.state.User.connected && !this.$store.state.App.dataRef) {
        temp = true
      }
      return temp
    },
    isTitleEditable () {
      return this.$store.getters['Tableau/isTitleEditable']
    }
  },
  methods: {
    updateLastSaveLabel () {
      const lastSave = this.$store.state?.App?.lastSave
      this.lastSaveLabel = lastSave ? this.$moment(lastSave).locale('fr').fromNow() : null
    },
    toggleViewerMode () {
      this.viewerMode = !this.viewerMode
      // quit all mode
      this.toggleActiveToolFromValue('pan')
      const canvas = this.$store.state.App.activeCanvas
      const objects = canvas.getObjects()
      if (objects.length > 0) {
        objects.forEach(element => {
          element.selectable = false
        })
      }
    },
    setName (e) {
      if (e.keyCode === 13) {
        this.saveNewName()
      }
    },
    saveNewName () {
      if (this.editName) {
        this.editName = false
      }
      this.$store.dispatch('Tableau/saveNewTitle', this.defaultName)
    },
    changeStateEdit () {
      this.editName = !this.editName
      if (this.editName) {
        this.$nextTick(() => {
          document.getElementById('inputName').focus()
        })
      }
    },
    async saveAndExit () {
      // faire un redirect vers l'espace enseignant (idealement le lien du tableau blanc lui-même.)
      // Reponse => Impossible on est dans un onglet différent https://stackoverflow.com/questions/12144135/switching-between-the-same-two-tabs-only-in-a-browser-using-javascript-and-html
      this.$store.commit('App/updateCanvasAvailable', this.createCanvasObject())
      await this.$store.dispatch('App/saveCanvas', true)

      // window.open will work only if the tab was opened by another script using window.open()
      if (window?.opener?.length) {
        window.close()
      }
    },
    saveLabel () {
      return window?.opener?.length ? 'Sauvegarder et quitter' : 'Sauvegarder'
    }
  },
  mounted () {
    setInterval(() => {
      this.updateLastSaveLabel()
    }, 1000);
  }
}
</script>

<template lang="pug">
.header-index
  .header-content__wrapper
    .side-content
      img.dokoma-logo(src="@/assets/images/logos/dokoma-icon.svg")
    //- div.document-title(v-if='$store.state.App.dataRef')
      h1 Le nom du document ici
    div.document-title
      span(v-if='$store.state.App.dataRef || $store.state.User.profile') {{$store.state.App.dataRef && $store.state.App.dataRef.proprietaire ? $store.state.App.dataRef.proprietaire.info.prenom + ' ' + $store.state.App.dataRef.proprietaire.info.nom : $store.state.User.profile.info.prenom + ' ' + $store.state.User.profile.info.nom}}
      span(v-else) Document anonyme
      div.title-wrapper
        h1(v-if='!editName') {{defaultName}}
        input(v-else, id='inputName' type='text', v-model='defaultName', :placeholder='defaultName', @keyup='setName')
        template(v-if='!viewerMode && isTitleEditable')
          button(@click='changeStateEdit', v-if='!editName', :disabled='!isProprietaire')
            font-awesome-icon(:icon="['fad', 'pencil']")
          button(@click='saveNewName', v-else, :disabled='!isProprietaire')
            font-awesome-icon(:icon="['fad', 'save']")

      div.save-time(v-if='!viewerMode && lastSaveLabel') Dernière sauvegarde: {{ lastSaveLabel }}
    .side-content
      //- v-popover.bottom-auto(offset='16', v-if='!viewerMode')
        button.icon
          font-awesome-icon(:icon="['fas', 'ellipsis-h']", style='font-size: 22px')
        template(slot='popover')
          .popover-custom__wrapper
            //- span allo
            //- pre {{viewerMode}}
            a(disabled) Télécharger le fichier (À venir)
            //- hr
            //- a Sauvegarde manuelle
      button.icon(v-if='$store.state.User.connected && isProprietaire', @click='toggleViewerMode', v-ripple, v-tooltip.bottom="{ content: viewerMode ? 'Mode édition' : 'Prévisualiser', delay: { show: 500, hide: 100 }, offset: '5'}")
        font-awesome-icon(:icon="['fad', viewerMode ? 'pencil' : 'play-circle']", style='font-size: 22px;')
      button.publish-button(v-if='$store.state.User.connected && !viewerMode && isProprietaire', @click='saveAndExit()', v-ripple)
        span {{ saveLabel() }}

      //- button(@click='showNotification = !showNotification') notif

</template>

<style lang='sass'>
@import 'src/assets/styles/layout/_header.sass'
</style>
