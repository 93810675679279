<script>
import mapStateTwoWay from '@/mixins/mapStateTwoWay'

export default {
  name: 'ArrowOptionsSelection',
  data: () => ({
    arrowTips: ['none', 'circle', 'caret'],
    sides: ['start', 'end']
  }),
  computed: {
    ...mapStateTwoWay([
      { n: 'Tools', k: 'start', m: 'setArrowStart', p: 'arrow.start' },
      { n: 'Tools', k: 'end', m: 'setArrowEnd', p: 'arrow.end' },
    ]),
  }
}
</script>

<template lang="pug">
.arrow-selection-container

  .arrow-selection-panel
    .input-block
      input(type="radio" value="none" id="start-none" v-model="start")
      label(for="start-none") Aucun
    .input-block
      input(type="radio" value="circle" id="start-circle" v-model="start")
      label(for="start-circle") Cercle
    .input-block
      input(type="radio" value="caret" id="start-caret" v-model="start")
      label(for="start-caret") Flèche

  .arrow-selection-panel
    .input-block
      input(type="radio" value="none" id="end-none" v-model="end")
      label(for="end-none") Aucun
    .input-block
      input(type="radio" value="circle" id="end-circle" v-model="end")
      label(for="end-circle") Cercle
    .input-block
      input(type="radio" value="caret" id="end-caret" v-model="end")
      label(for="end-caret") Flèche
</template>

<style lang='sass' scoped>
.arrow-selection-container
  display: flex
  justify-content: space-around
  padding: 0 12px
  .input-block
    display: flex
    align-items: center
    input
      margin-right: 6px
</style>
