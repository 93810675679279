<script>
export default {
  name: 'Unauthorized'
}
</script>

<template lang="pug">
.unauthorized-container(v-if="$store.state.User.isUnauthorized")
  .unauthorized-box
    h1 Non autorisé
    p Vous devez être connecté et avoir accès à ce tableau pour pouvoir le consulter.
    p Pour qu'un élève ait accès au tableau blanc d'un de ses professeurs, ce dernier doit lui attribuer le tableau blanc depuis son espace Enseignant.
</template>

<style lang="sass" scoped>
.unauthorized-container
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(0,0,0,0.3)
  z-index: 200
  .unauthorized-box
    box-sizing: border-box
    padding: 40px 100px
    min-width: 620px
    width: 620px
    height: 400px
    background-color: #fff
    border-radius: 18px
    font-size: 22px
    background-color: var(--primary)
    box-shadow: 8px 8px 18px rgba(0,0,0,0.3)
</style>