<script>
export default {
  name: 'ArrowOptionsColor',
  watch: {
      arrowBgColor: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          document.documentElement.style.setProperty('--arrow-color', this.arrowBgColor)
        }
      }
    }
    },
  computed: {
    arrowBgColor: {
      get () { return this.$store.state.Tools.arrow.color },
      set (val) {
        if (this.$store.state.Tableau.selection) {
          const typo = this.$store.state.Tableau.selection.get('fill')
          if (val !== typo) {
            this.$store.state.Tableau.selection._objects.forEach(element => {
              if (element.stroke === "") {
                element.set('fill', val)
              } else {
                element.set('stroke', val)
              }
            })
            this.$store.state.Tableau.selection.isEditing = false
            this.$store.state.App.activeCanvas.renderAll()
          }
        }
        this.$store.commit('Tools/setArrowColor', val)
      }
    },
  }
}
</script>

<template lang="pug">
.arrow-color-container
    .option-wrapper(style='display: flex;')
    input.hex-input(v-model='arrowBgColor', type='text', maxlength='7')
    label.color-selector()
        input#favcolor(v-model='arrowBgColor', type='color', style='opacity:0;')
        div.arrow-color-tag()
</template>

<style lang='sass' scoped>
.arrow-color-container
  width: 100%
  height: 45px
  display: flex
  align-items: center
  justify-content: center
  margin: 15px 0
</style>
