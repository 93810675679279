const debounce = require('lodash.debounce')
export default {
  computed: {
    drawTool () {
      return this.$store.state.Tools.draw
    },
    cursorSize () {
      return this.drawTool.lineWidth * this.$store.state.Tableau.canvasZoom
    }
  },
  watch: {
    '$store.state.Tools.draw': {
      deep: true,
      handler: debounce( function () {
        this.updateNewCursor()
      }, 500, { trailing: true})
    },
    '$store.state.Tableau.canvasZoom' () {
      this.updateNewCursor()
    }
  },
  methods: {
    updateNewCursor () {
      this.fabriCanvas.freeDrawingCursor = 'none'
      this.fabriCanvas.freeDrawingCursor = `url(${ this.getDrawCursor() }) ${ this.drawTool.lineWidth / 2 + 5 } ${ this.drawTool.lineWidth / 2 + 5 }, crosshair`
      this.fabriCanvas.isDrawingMode = this.drawTool.drawMode
      this.fabriCanvas.freeDrawingBrush.color = this.drawTool.brushColor
      this.fabriCanvas.freeDrawingBrush.width = parseInt(this.drawTool.lineWidth)
    },
    // The cursor will be ignored if its size is bigger than 128px...
    // À CHANGER POUR UNE AUTRE FONCTION PLSU CLEAN https://jsfiddle.net/Fidel90/7kmf3jz2/
    getDrawCursor () {
      const circle = `
      	<svg
      		height="${ this.cursorSize }"
          width="${ this.cursorSize }"
      		viewBox="0 0 ${ this.cursorSize * 2 + 10 } ${ this.cursorSize * 2 + 10}"
      		xmlns="http://www.w3.org/2000/svg"
      	>
      		<circle
            fill="${ this.drawTool.brushColor}"
            stroke='black'
            stroke-width='5'
      			cx="50%"
      			cy="50%"
      			r="${ this.cursorSize }"
            opacity="0.8"
      		/>
      	</svg>
      `;
      return `data:image/svg+xml;base64,${ window.btoa(circle) }`;
    },
    setDrawingMode (value) {
      this.$store.commit('Tools/setDrawMode', value)
      this.fabriCanvas.isDrawingMode = value
      this.fabriCanvas.freeDrawingBrush.width = parseInt(this.$store.state.Tools.draw.lineWidth)
      this.fabriCanvas.freeDrawingBrush.color = this.$store.state.Tools.draw.brushColor
    },
    changeDrawingColor (value) {
      this.fabriCanvas.freeDrawingBrush.color = value
      this.fabriCanvas.isDrawingMode = true
      this.fabriCanvas.renderAll()
    },
    changeDrawingWidth (value) {
      this.fabriCanvas.freeDrawingBrush.width = value
      this.fabriCanvas.isDrawingMode = true
      this.fabriCanvas.renderAll()
    },
    setPatternBrush (value) {
      console.log('on se servira du value pour des patterns prédéfini', value)
      const temp = new this.$Fabric.PatternBrush(this.fabriCanvas)
      this.fabriCanvas.freeDrawingBrush.freeDrawingBrush = temp
      // il faut change l'event path en fonction du pattern brush
      // const thus = this
      // this.fabriCanvas.on('path:created', (e) => {
      //   e.path.set()
      //   this.fabriCanvas.renderAll()
      //   this.debounce(() => {
      //     thus.emitSave()
      //   }, 200)
      // })
    },
    emitSave: debounce( function () {
      // TODO REMPLACER PAR FUNCTION GLOBAL
    }, 500, { trailing: true })
  }
}
