<script>
export default {
  name: 'TextOptions',
    data () {
      return {
        // selectedFont: 'red-hat-text',
        availableFonts: [
          {title: 'Red Hat Text', value:'Red Hat Text'},
          {title: 'Source Serif Pro', value:'Source Serif Pro'},
          {title: 'Arial', value:'arial'},
          {title: 'Helvetica', value:'helvetica'},
          {title: 'Roboto', value:'Roboto'},
          {title: 'Radio Canada', value:'Radio Canada'},
          {title: 'Satisfy', value:'Satisfy'},
          {title: 'Alfa Slab One', value:'Alfa Slab One'},
        ],
        availableColors: [
          {title: 'Red', value:'#ff0000'},
          {title: 'White', value:'#FFFFFF'},
          {title: 'Yellow', value:'#FFFF00'}
        ],
        // selectedFontSize: 16,
        fontSizes: [
          10, 12, 14, 16, 18, 20, 24, 30, 40, 64, 80, 100, 120
        ],
        activeTextOptions: [],
        activeTextAlignement: 'center',
        textOptionObject : {
          bold: {
            properties: "fontWeight",
            tools: "setFontWeight",
            valid: "bold",
            invalide: "normal"
          },
          italic: {
            properties: "fontStyle",
            tools: "setFontStyle",
            valid: "italic",
            invalide: "normal"
          },
          underline: {
            properties: "underline",
            tools: "setUnderLine",
            valid: true,
            invalide: false
          },
          strike: {
            properties: "linethrough",
            tools: "setLineThrough",
            valid: true,
            invalide: false
          }
        }
      }
    },
    watch: {
      textBgColor: {
      immediate: true,
      handler (newVal) {
        if (newVal) {
          document.documentElement.style.setProperty('--txtbg-color', this.textBgColor)
        }
      }
    },
    '$store.state.Tableau.selection': {
      handler (newVal) {
        if (newVal) {
          this.setTextOption(newVal)
        }
      }
    }
    },
  computed: {
    selectedFont: {
      get () {
        return this.$store.state.Tools.text.fontSelected
      },
      set (val) {
        if (this.$store.state.Tableau.selection) {
          const typo = this.$store.state.Tableau.selection.get('fontFamily')
          if (val !== typo) {
            this.$store.state.Tableau.selection.set('fontFamily', val)
            this.$store.state.App.activeCanvas.renderAll()
            this.$store.state.Tableau.selection.enterEditing()
          }
        }
        this.$store.commit('Tools/setFontSelected', val)
      }
    },
    textBgColor: {
      get () {
        return this.$store.state.Tools.text.textBackgroundColor
      },
      set (val) {
        if (this.$store.state.Tableau.selection) {
          const typo = this.$store.state.Tableau.selection.get('textBackgroundColor')
          if (val !== typo) {
            this.$store.state.Tableau.selection.set('textBackgroundColor', val)
            this.updateStore()
          }
        }
        this.$store.commit('Tools/setTextBackgroundColor', val)
      }
    },
    selectedFontSize: {
      get () {
        return this.$store.state.Tools.text.fontSize
      },
      set (val) {
        if (this.$store.state.Tableau.selection) {
          const fontSelection = this.$store.state.Tableau.selection.get('fontSize')
          // this.$store.commit('')
          if (val !== fontSelection) {
            console.log('faut changer la font dans le text ou on est', fontSelection)
            this.$store.state.Tableau.selection.set('fontSize', val)
            // faut rerender
            this.$store.state.App.activeCanvas.renderAll()
            this.$store.state.Tableau.selection.enterEditing()
          }
        }
        this.$store.commit('Tools/setFontSize', val)
      }
    },
    selectedTextAlign: {
      get () { return this.$store.state.Tools.text.textAlign },
      set (val) { 
        this.$store.state.Tableau?.selection?.set('textAlign', val)
        this.$store.commit('Tools/setTextAlign', val)
        this.updateStore()
        }
    }
  },
  methods: {
    toggleTextOption (tool) {
      let foundIndex = this.activeTextOptions.findIndex(el => el === tool)
      let {properties, tools, value} = this.getOptions (tool, true)
      if (foundIndex < 0) {
        this.activeTextOptions.push(tool)
        this.$store.state.Tableau?.selection?.set(properties, value)
        this.$store.commit('Tools/' + tools, tool)
      } else {
        let {properties, value, tools} = this.getOptions(tool, false)
        this.activeTextOptions.splice(foundIndex, 1)
        this.$store.state.Tableau?.selection?.set(properties, value)
        this.$store.commit('Tools/' + tools, value)
      }
      this.updateStore()
    },
    toggleTextAlignement (position) {
      this.selectedTextAlign = position
    },
    updateStore () {
      this.$store.state.App.activeCanvas.renderAll()
      if( this.$store.state.Tableau.selection ) {
        this.$store.state.Tableau.selection.enterEditing()
      } 
    },
    getOptions (tool, val) {
      return {
        properties: this.textOptionObject[tool].properties,
        tools: this.textOptionObject[tool].tools,
        value: this.textOptionObject[tool][val ? "valid" : "invalide"]
      }
    },
    setTextOption (val) {
      this.activeTextOptions = []
      this.$store.commit('Tools/setFontWeight', 'normal')
      this.$store.commit('Tools/setFontStyle', 'normal')
      this.$store.commit('Tools/setLineThrough', false)
      this.$store.commit('Tools/setUnderLine', false)

      const {fontWeight, fontStyle, linethrough, underline} = val
      
      if (fontWeight === 'bold') {
        this.activeTextOptions.push(fontWeight)
        this.$store.commit('Tools/setFontWeight', fontWeight)
      }
      if (fontStyle === 'italic') {
        this.activeTextOptions.push(fontStyle)
        this.$store.commit('Tools/setFontStyle', fontStyle)
      }
      if (linethrough) {
        this.$store.commit('Tools/setLineThrough', true)
        this.activeTextOptions.push('strike')
      }
      if (underline) {
        this.$store.commit('Tools/setUnderLine', true)
        this.activeTextOptions.push('underline')
      }
    }
  }
}
</script>

<template lang="pug">
.tool-index.text-options
  .tool-section
    section.tool-option.vertical
      label.tool-option--title
        | Police de caractère
      .tool-option--control
        select(v-model='selectedFont', style='width: 100%')
          option(v-for='(option, index) in availableFonts', :value='option.value') {{option.title}}
    section.tool-option
      label.tool-option--title
        | Taille de caractère
      .tool-option--control
        select(v-model='selectedFontSize', style='width: 100%')
          option(v-for='(option, index) in fontSizes', :value='option') {{option}}
    section.tool-option.vertical
      label.tool-option--title
        | Options de texte
      .tool-option--control
        .multi-options__wrapper
          button(@click='toggleTextOption("bold")', :class='{"active-option":activeTextOptions.find((el) => el === "bold")}')
            font-awesome-icon(:icon="['fas', 'bold']", style='font-size: 16px')
          button(@click='toggleTextOption("italic")', :class='{"active-option":activeTextOptions.find((el) => el === "italic")}')
            font-awesome-icon(:icon="['fas', 'italic']", style='font-size: 16px')
          button(@click='toggleTextOption("strike")', :class='{"active-option":activeTextOptions.find((el) => el === "strike")}')
            font-awesome-icon(:icon="['fas', 'strikethrough']", style='font-size: 16px')
          button(@click='toggleTextOption("underline")', :class='{"active-option":activeTextOptions.find((el) => el === "underline")}')
            font-awesome-icon(:icon="['fas', 'underline']", style='font-size: 16px')
    section.tool-option.vertical
      label.tool-option--title
        | Alignement
      .tool-option--control
        .multi-options__wrapper
          button(@click='toggleTextAlignement("left")', :class='{"active-option":selectedTextAlign === "left"}')
            font-awesome-icon(:icon="['fas', 'align-left']", style='font-size: 16px')
          button(@click='toggleTextAlignement("center")', :class='{"active-option":selectedTextAlign === "center"}')
            font-awesome-icon(:icon="['fas', 'align-center']", style='font-size: 16px')
          button(@click='toggleTextAlignement("right")', :class='{"active-option":selectedTextAlign === "right"}')
            font-awesome-icon(:icon="['fas', 'align-right']", style='font-size: 16px')
    section.tool-option.vertical
      label.tool-option--title
        |  Couleur de Background
      .option-wrapper(style='display: flex;')
        input.hex-input(v-model='textBgColor', type='text', maxlength='7')
        label.color-selector()
          input#favcolor(v-model='textBgColor', type='color', style='opacity:0;')
          div.txtbg-color-tag()

</template>

<style lang='sass'>
// .text-options
  .tool-option--control
    .multi-options__wrapper
      display: flex
      margin-top: 5px
      border: solid 1px var(--secondary)
      border-radius: 10px
      overflow: hidden
      button
        flex-grow: 1
        height: 30px
        outline: none
        &:not(:last-of-type)
          border-right: solid 1px var(--secondary)
        &.active-option
          background-color: var(--secondary)
          color: white
          &:not(:last-of-type)
            border-right: solid 1px white
</style>
