
<script>
export default {
  name: "voiceOptions",
  computed: {
    showAudioWave: {
      get () { return this.$store.state.Tools.audio.audioWave },
      set () { this.$store.commit('Tools/setAudioWave', true) }
    },
    myAudio () {
      return this.$store.state.Tools.audio.recorderToLire
    }
  },
  methods: {
    toggleAudioWave () {
      this.$store.commit('Tools/setAudioWave', false)
    }
  }
  }
</script>
<template lang="pug">
.audio-index(v-if='showAudioWave')
  .modal(style='margin-bottom: 270px;')
    perfect-scrollbar.modal-scroller(id='pages-scroller')
      .modal-header
        button.close-button(@click='toggleAudioWave')
          font-awesome-icon(:icon="['fas', 'times']", style='font-size: 24px')
      .audio-content__wrapper
        av-waveform.waveform(:audio-src='myAudio', :canv-width='400', :canv-height='70', noplayed-line-color='rgb(61, 44, 57)', played-line-color='rgb(252, 96, 85)')
</template>

<style lang="sass" scoped>
.audio-index
  z-index: 100
  position: absolute
  top: 0
  left: 0
  display: flex
  flex-direction: column
  justify-content: center
  align-items: center
  width: 100%
  height: 100%
  .modal
    background-color: var(--primary)
    border-radius: 10px
    .modal-header
      display: flex
      flex-direction: column
      align-items: flex-end
      .close-button
        background-color: var(--primary)
        border-radius: 5px
        margin: 4px 4px
    .audio-content__wrapper
      padding: 10px 10px
      .waveform::v-deep
        display: flex
        flex-direction: column-reverse
        div audio
          width: 100% !important
    
</style>