import Vue from 'vue'
import Vuex from 'vuex'
import App from './modules/app'
import User from './modules/user'
import Tools from './modules/tools'
import Tableau from './modules/tableau'
Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    App,
    User,
    Tools,
    Tableau
  }
})
