<script>
import AudioComment from '@/components/tableau/tools/audioComment.vue'

export default {
  name: 'AudioModal',
  components: {AudioComment},
  data () {
    return {
      mathField: null
    }
  },
  computed: {
    showAudioPreview: {
      get () {return this.$store.state.Tools.showAudioPreview},
      set (val) {this.$store.commit('Tools/setShowAudioPreview', val)}
    }
  },
  methods: {
    toggleAudio () {
      this.showAudioPreview = !this.showAudioPreview
    }
  }
}
</script>

<template lang="pug">

.modal-index(v-if='showAudioPreview')
  a.modal-overlay(@click='toggleAudio', :class='{"visible-overlay" : showAudioPreview}')
  .modal(style='margin-bottom: 270px;')
    perfect-scrollbar.modal-scroller(id='pages-scroller')
      .modal-header
        span Audio
        button.close-button(@click='toggleAudio')
          font-awesome-icon(:icon="['fas', 'times']", style='font-size: 24px')
      .modal-content__wrapper
        audio-comment(:active='true', :showWave='true')
</template>

<style lang='sass'>
  @import 'src/assets/styles/base/_modal.sass'
</style>
