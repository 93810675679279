// This custom cursor may cause flickering when moving the cursor if "Disable cache"
// option has been checked by the user. Didnt found anything yet to avoid that
const eraserCursor = 'url("/img/cursors/eraser-light.png") -31 31, auto'
const editCursor = 'url("/img/cursors/edit.png") -31 31, auto'
import mapStateTwoWay from '@/mixins/mapStateTwoWay'

export default {
  computed: {
    ...mapStateTwoWay([
      { n: 'Tools', k: 'activeTool', m: 'setActiveTool' },
    ])
  },
  data () {
    return {
      toolset: [
        { title: '[H] Se déplacer', value: 'pan', icon: { type: 'fas', name: 'hand-paper' }, cursor: 'grab', hoverCursor: 'grab', hideOptions: true },
        { title: '[V] Sélectioner et Modifier', value: 'select', icon: { type: 'fas', name: 'mouse-pointer' }, cursor: 'default', hideOptions: true },
        { title: '[B] Crayon', value: 'draw', icon: { type: 'fad', name: 'pencil' }, cursor: 'default' },
        { title: '[E] Effacer', value: 'erase', icon: { type: 'fad', name: 'eraser' }, cursor: eraserCursor, hoverCursor: eraserCursor, hideOptions: true },
        { title: '[T] Texte', value: 'text', icon: { type: 'fas', name: 'text' }, cursor: 'text', hoverCursor: 'text' },
        { title: '[M] Formule mathématique', value: 'math', icon: { type: 'fad', name: 'function' }, cursor: 'text' },
        { title: '[D] Desmos', value: 'desmos', icon: { type: 'fad', name: 'wave-sine' }, hoverCursor: editCursor, hideOptions: false },
        { title: '[R] Formes', value: 'shapes', icon: { type: 'fad', name: 'square' } },
        { title: '[Y] Vidéos Youtube', value: 'video', icon: { type: 'fad', name: 'video' }, cursor: 'grab' },
        { title: '[I] Image', value: 'image', icon: { type: 'fad', name: 'image' }, cursor: 'default', hideOptions: true },
        { title: "[L] Liens internes", value: 'links', icon: { type: 'fad', name: 'bullseye-arrow' }, cursor: 'default', hideOptions: true },
        { title: '[U] Flèches et lignes', value: 'arrow', icon: { type: 'fad', name: 'long-arrow-alt-up' }, cursor: 'default' },
        { title: '[A] Vocales', value: 'audio', icon: { type: 'fad', name: 'microphone' }, cursor: 'default' },
      ]
    }
  },
  methods: {
    toggleActiveTool (val) {
      if (this.activeTool !== val) {
        this.activeTool = val ? val : this.toolset[0]
      }
    },
    toggleActiveToolFromValue (toolValue) {
      const toolIndex = this.toolset.findIndex(tool => tool.value === toolValue)
      this.activeTool = this.toolset[toolIndex !== -1 ? toolIndex : 0]
    }
  }
}
