import { v4 as uuidv4 } from 'uuid'
export default {
  watch: {
    '$store.state.Tools.desmosToInsert.desmosSvg' (newVal) {
      this.createDesmosSVG(newVal, this.$store.state.Tools.coordToInsert)
    }
  },
  methods: {
    createDesmosSVG (val, canvasCoord) {
      // Hardcoded fix for parenteses
      const fixedValue = val.replaceAll('\\mleft', '').replaceAll('\\mright?', '').replaceAll('\\mright', '').replaceAll('\\exponentialE', 'e')
      const wrap = window.MathJax.tex2svg(fixedValue)
      const svgHtml = wrap.children[0]
      this.insertDesmosSvg({ svg: svgHtml, base: val }, canvasCoord)
    },
    insertDesmosSvg (val, coord) {
      const mathSvgString = val.base
      const desmosExpressions = this.$store.state.Tools.desmosToInsert.desmosExpressions
      this.$Fabric.loadSVGFromString(mathSvgString, (objects, options) => {
        const group = this.$Fabric.util.groupSVGElements(objects, options)
        group.set({
          id: uuidv4(),
          top: coord.y,
          left: coord.x
        })
        const canvasWidth = this.fabriCanvas.width
        group.custom = { isDesmos: true, mathSvgString, desmosExpressions, type: 'desmos', canvasWidth, tl: group.aCoords.tl, br: group.aCoords.br }
        // to update desmos we need to delete the element and add a new one
        // 1- delete
        if (this.fabriCanvas.getActiveObject() && this.fabriCanvas.getActiveObject().custom?.type === 'desmos') {
          console.debug('remove5')
          this.fabriCanvas.remove(this.fabriCanvas.getActiveObject())
        }
        // 2- add 
        this.fabriCanvas.add(group)
      })
      this.fabriCanvas.renderAll()
    }
  }
}
