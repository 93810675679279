import { render, staticRenderFns } from "./desmosOptions.vue?vue&type=template&id=3899fc34&scoped=true&lang=pug&"
import script from "./desmosOptions.vue?vue&type=script&lang=js&"
export * from "./desmosOptions.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3899fc34",
  null
  
)

export default component.exports