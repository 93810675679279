import { apolloClientV2 } from '@/setup/apollo.js'
import VueCookies from 'vue-cookies'

const state = {
  connected: false,
  profile: null,
  isUnauthorized: false
}

const actions = {
  async initUser ({ commit, dispatch }) {
    // on peut aussi check pour le cookie natif
    const cookieNatif = document.cookie.split(';')
    const token = VueCookies.get('ClassDokomaToken') || ( cookieNatif.length > 0 && cookieNatif.find(e => e.includes('ClassDokomaToken')) ? cookieNatif.find(e => e.includes('ClassDokomaToken')).split('=')[1] : null )
    if (token) {
      // on a un token on regarde si il est toujours valide
      try {
        await apolloClientV2.query({
          query: require('@/apollo/queries/salleClasseUsagerMin.gql'),
          fetchPolicy: 'network-only'
        }).then(({ data }) => {
          if (data.salleClasseUsager) {
            commit('setConnected', true)
            commit('setProfile', data.salleClasseUsager)
          }
        })
      } catch (e) {
        console.error('Token pas valable', e)
        // on va regardé si on a un enseignant user pour le reloger
        if (VueCookies.get('ClassUser')) {
          //on supprime le cookie existant
          if (VueCookies.get('ClassDokomaToken')) {
            VueCookies.remove('ClassDokomaToken', '/', '.dokoma.com')
          }
          dispatch('connectUser')
        } else {
          console.error('AUCUNE INFO POUR LOGGER LE USER TODO VOIR COMMENT ON GÈRE ÇA')
        }
      }
    } else if (VueCookies.get('ClassUser')) {
      await dispatch('connectUser')
    }
  },
  async connectUser ({ commit }) {
    if (VueCookies.get('ClassUser')) {
      await apolloClientV2.mutate({
        mutation: require('@/apollo/mutations/identificationSalleClasse.gql'),
        variables: {
          username: VueCookies.get('ClassUser').username,
          accessCode: VueCookies.get('ClassUser').access_code
        }
      }).then(({ data }) => {
        if (data.identificationSalleClasse) {
          const dateExp = new Date()
          dateExp.setDate(dateExp.getDate() + 1)
          VueCookies.set('ClassDokomaToken', data.identificationSalleClasse, new Date(parseInt(dateExp.toString() + '000')), '/', '.dokoma.com')
          commit('setConnected', true)
        } else {
          console.error('IMPOSSIBLE DE CONNECTER LE USER TODO GESTION CAS')
        }
      })
    }
  }
}

const mutations = {
  setConnected (state, payload) {
    state.connected = payload
  },
  setProfile (state, payload) {
    state.profile = payload
  },
  setIsUnauthorized (state, payload) {
    state.isUnauthorized = payload
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
