import { v4 as uuidv4 } from 'uuid'
export default {
  data () {
    return {
      origX: null,
      origY: null,
      activeShape: null
    }
  },
  methods: {
    setShapeMode (val) {
      console.log('on va mettre une shape', val)
    },
    resetShape () {
      this.origY = null
      this.origX = null
      this.activeShape = null
    },
    drawActiveShape (x, y) {
      this.origX = x
      this.origY = y
      if (this.$store.state.Tools.shape.active === 'circle') {
        this.drawCircle()
      } else if (this.$store.state.Tools.shape.active === 'rectangle') {
        this.drawRectangle()
      } else if (this.$store.state.Tools.shape.active === 'triangle') {
        this.drawTriangle()
      }
      this.activeShape['custom'] = { type: 'shape' }
      this.activeShape.set(this.$store.state.Tools.optionsBoundingBox)
      this.fabriCanvas.add(this.activeShape)
    },
    drawCircle () {
      this.activeShape = new this.$Fabric.Circle({
        id: uuidv4(),
        left: this.origX,
        top: this.origY,
        originX: 'left',
        originY: 'top',
        radius: 0,
        // selectable: false,
        fill: this.$store.state.Tools.shape.fillColor,
        stroke: this.$store.state.Tools.shape.strokeColor,
        strokeWidth: parseInt(this.$store.state.Tools.shape.strokeWidth)
      })
      this
    },
    drawRectangle () {
      this.activeShape = new this.$Fabric.Rect({
        id: uuidv4(),
        left: this.origX,
        top: this.origY,
        width: 1,
        height: 1,
        // selectable: false,
        fill: this.$store.state.Tools.shape.fillColor,
        stroke: this.$store.state.Tools.shape.strokeColor,
        strokeWidth: parseInt(this.$store.state.Tools.shape.strokeWidth)
      })
    },
    drawTriangle () {
      this.activeShape = new this.$Fabric.Triangle({
        id: uuidv4(),
        left: this.origX,
        top: this.origY,
        width: 50,
        height: 50,
        originX: 'left',
        originY: 'top',
        // selectable: false,
        fill: this.$store.state.Tools.shape.fillColor,
        stroke: this.$store.state.Tools.shape.strokeColor,
        strokeWidth: parseInt(this.$store.state.Tools.shape.strokeWidth)
      })
    },
    updateActiveShape (x, y) {
      if (this.$store.state.Tools.shape.active === 'circle') {
        this.updateCircle(x, y)
      } else if (this.$store.state.Tools.shape.active === 'rectangle') {
        this.updateRectangle(x, y)
      } else if (this.$store.state.Tools.shape.active === 'triangle') {
        this.updateTriangle(x, y)
      }
      this.fabriCanvas.renderAll()
    },
    updateCircle (x, y) {
      let radius = Math.max(Math.abs(this.origY - y), Math.abs(this.origX - x)) / 2
      if (radius > this.activeShape.strokeWidth) {
        radius -= this.activeShape.strokeWidth / 2
      }
      this.activeShape.set({ radius })
      if (this.origX > x) {
        this.activeShape.set({ originX: 'right' })
      } else {
        this.activeShape.set({ originX: 'left' })
      }
      if (this.origY > y) {
        this.activeShape.set({ originY: 'bottom' })
      } else {
        this.activeShape.set({ originY: 'top' })
      }
    },
    updateRectangle (x, y) {
      const newWidth = Math.abs(this.origX - x)
      const newHeight = Math.abs(this.origY - y)
      this.activeShape.set({ width: newWidth, height: newHeight })
      if (this.origX > x) {
        this.activeShape.set({ originX: 'right' })
      } else {
        this.activeShape.set({ originX: 'left' })
      }
      if (this.origY > y) {
        this.activeShape.set({ originY: 'bottom' })
      } else {
        this.activeShape.set({ originY: 'top' })
      }
    },
    updateTriangle (x, y) {
      const newWidth = Math.abs(this.origX - x)
      const newHeight = Math.abs(this.origY - y)
      this.activeShape.set({ width: newWidth, height: newHeight })
      if (this.origX > x) {
        this.activeShape.set({ originX: 'right' })
      } else {
        this.activeShape.set({ originX: 'left' })
      }
      if (this.origY > y) {
        this.activeShape.set({ originY: 'bottom' })
      } else {
        this.activeShape.set({ originY: 'top' })
      }
    }
  }
}