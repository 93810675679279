import Vue from 'vue'
import App from './App.vue'
import store from './store'
import Fabric from 'fabric'
import moment from 'moment'
import VueCookies from 'vue-cookies'
import apolloProvider from '@/setup/apollo'
import vSelect from 'vue-select'
import WebFont from 'webfontloader'

import './plugins/fontawesome'
import './plugins/vueperfectscrollbar'
import './plugins/vtooltip'
import './plugins/vueaudiovisual'
import './plugins/vripple'
import 'vue-select/dist/vue-select.css'

WebFont.load({
  google: {
    families: ["Alfa Slab One:normal,italic", "Radio Canada:normal,italic", "Red Hat Text:normal,italic", "Roboto:normal,italic", "Satisfy:normal,italic", "Source Serif Pro:normal,italic"]
  }
})

// Cache disabled because the vectorial texts and images looked blurry
const FabricCanvas = Fabric.fabric
FabricCanvas.Object.prototype.objectCaching = false
Vue.prototype.$Fabric = FabricCanvas

Vue.prototype.$moment = moment
Vue.prototype.$cookies = VueCookies
Vue.component('v-select', vSelect)

Vue.config.productionTip = false

new Vue({
  store,
  apolloProvider,
  render: h => h(App),
}).$mount('#app')
