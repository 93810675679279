// import { MathfieldElement } from "mathlive"
// import 'mathlive/dist/mathlive-fonts.css'
// import 'mathlive/dist/mathlive-static.css'
// import debounce from 'lodash.debounce'
import { v4 as uuidv4 } from 'uuid'
export default {
  data () {
    return {
      mathElem: null
    }
  },
  watch: {
    '$store.state.Tools.mathValueToInsert' (newVal) {
      if (!this.showMathPreview && newVal) {
        this.createSVG(newVal, this.$store.state.Tools.coordToInsert)
      }
    }
  },
  methods: {
    setMathField (windowCoord, canvasCoord, value) {
      this.$store.commit('Tools/setCoordToInsert', canvasCoord)
      this.showMathPreview = true
      if (value) {
        this.$store.commit('Tools/setMathOldValue', value)
      }
      // const wrapper = document.createElement('div')
      // wrapper.id = 'mathwrapper'
      // wrapper.style.position = 'absolute'
      // // 66px pour enlever la hauteur du header et de la toolbar
      // wrapper.style.left = `${windowCoord.x - 66}px`
      // wrapper.style.top = `${windowCoord.y - 66}px`
      // wrapper.style.fontSize = this.$store.state.Tools.text.fontSize + 'px'
      // const temp = new MathfieldElement()
      // temp.setOptions({
      //   virtualKeyboardMode: 'onfocus',
      //   locale: 'fr-CA'
      // })
      // if (value) {
      //   temp.value = value
      // }
      // wrapper.appendChild(temp)
      // document.querySelector('.canvas-container').appendChild(wrapper)
      // setTimeout(() => {
      //   temp.focus()
      //   temp.addEventListener('blur', debounce((ev) => {
      //     this.createSVG(ev.target.value, canvasCoord)
      //   }, 200, { trailing: true }))
      // }, 200)
    },
    async createSVG (val, canvasCoord) {
      // Hardcoded fix for parenteses
      const fixedValue = val.replaceAll('\\mleft', '').replaceAll('\\mright?', '').replaceAll('\\mright', '').replaceAll('\\exponentialE', 'e')
      let wrap = window.MathJax.tex2svg(fixedValue)
      const svgHtml = wrap.children[0]
      this.insertMathSvg({ svg: svgHtml, base: val }, canvasCoord)
    },
    insertMathSvg (val, coordo) {
      const mathSvgString = val.svg.outerHTML
      this.$Fabric.loadSVGFromString(mathSvgString, (objects, options) => {
        const group = this.$Fabric.util.groupSVGElements(objects, options)
        group.set({
          id: uuidv4(),
          top: coordo.y,
          left: coordo.x,
        })
        group.scale(0.75 * this.$store.state.Tools.text.fontSize)
        group.set(this.$store.state.Tools.optionsBoundingBox)
        group['custom'] = { mathSvgString: val.base, type: 'math' }
        this.fabriCanvas.add(group)
      })
      this.fabriCanvas.renderAll()
      this.editMath = false
      this.$store.commit('Tools/insertMath', null)
      this.$store.commit('Tools/setCoordToInsert', null)
    }
  }
}
