<script>
export default {
  name: 'CanvasLoader',
  data () {
    return {
      progress: 0
    }
  },
  methods: {
    start() {
      setInterval(() => {
        if (this.progress >= 100) {
            clearInterval()
        } else {
          this.progress += 5
        }
      }, 200)
    }
  },
  mounted () {
    this.start()
    setTimeout(() => {
      this.$store.commit('App/setCanvasLoader', false)
    }, 4000)
  }
}
</script>

<template lang="pug">
.loading-indicator-container
  .loading
    .shell
      .bar(:style="{ width: progress + '%' }")
</template>

<style lang="sass" scoped>
.loading-indicator-container
  position: absolute
  z-index: 10
  background-color: white
  width: 100%
  height: 100%
  .loading
    position: relative
    display: flex
    align-items: center
    justify-content: center
    height: inherit
    .shell
      height: 20px
      width: 450px
      border: 1px solid #aaa
      border-radius: 13px
      padding: 3px
      .bar
        background: linear-gradient(to right, #B993D6, #8CA6DB)
        height: 20px
        width: 15px
        border-radius: 9px
</style>