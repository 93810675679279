<script>
import tableauControls from '@/mixins/tableauControls'
import TableauPages from '@/mixins/TableauPages'
import mapStateTwoWay from '@/mixins/mapStateTwoWay'

export default {
  name: 'Administrator',
  mixins: [tableauControls, TableauPages],
  computed: {
    ...mapStateTwoWay([
      { n: 'Tableau', k: 'activePage', m: 'setActivePage' },
      { n: 'App', k: 'showPages', m: 'setShowPages' },
    ]),
    canvasAvailable () {
      return this.$store.state.App.canvasAvailable
    },
    isLoading () {
      return this.$store.state.Tableau.loadingIndicator !== 0
    }
  },
  methods: {
    openTab () {
      this.showPages = !this.showPages
      if (this.showPages) {
        this.$store.commit('App/updateCanvasAvailable', this.createCanvasObject())
      }
    }
  }
}
</script>


<template lang="pug">
div.tableau-label__wrapper

  button.control-button.start(:disabled='(activePage <= 1) || isLoading', @click='goToPage(activePage - 1)')
    font-awesome-icon(:icon="['fas', 'angle-left']")
  button.control-button.end(:disabled='(activePage >= canvasAvailable.length) || isLoading', @click='goToPage(activePage + 1)')
    font-awesome-icon(:icon="['fas', 'angle-right']")

  v-popover.bottom(offset='0' :disabled='isLoading')

    button.tableau-label
      span Page {{$store.state.Tableau.activePage}}
      font-awesome-icon(:icon="['fas', 'angle-down']", style='font-size: 12px; margin-left: 10px')

    template(slot='popover')
      .popover-custom__wrapper.mini(v-close-popover)
        ul.pages-list
          li.page-list--item(v-for='(n, index) in canvasAvailable', @click='goToPage(n.id)') Page {{ n.id }}
          li.page-list--item(v-if='!$store.state.App.viewerMode' @click='openTab') Voir/Ajouter des pages
</template>
