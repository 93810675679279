<script>
export default {
  name: 'ImagePopinHeader'
}
</script>

<template lang="pug">
.popin-header
  .popin-header-left
  .popin-header-title
    font-awesome-icon(:icon="['fad', 'image']")
    span Images
  .popin-header-right
    font-awesome-icon.pointer(:icon="['fad', 'times-circle']" @click="$emit('close')")
</template>

<style lang="sass" scoped>
.popin-header
  position: relative
  display: flex
  align-items: center
  justify-content: space-between
  height: 40px
  border-radius: 18px 18px 0 0
  overflow: hidden
  &::after
    content: ''
    position: absolute
    top: 0
    left: 0
    right: 0
    bottom: 0
    background-color: var(--secondary)
    opacity: 0.25
    z-index: 5
  > *
    z-index: 10
  .popin-header-left, .popin-header-right
    display: flex
    width: 30px
    align-items: center
    padding: 0 20px
  .popin-header-left
    justify-content: start
  .popin-header-right
    justify-content: end
  .popin-header-title
    display: flex
    font-weight: bold
    gap: 10px
</style>
