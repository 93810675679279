<script>
export default {
  name: 'PositionIndicator',
  computed: {
    positionText () {
      const x = this.$store.state.Tableau.canvasPosX
      const y = this.$store.state.Tableau.canvasPosY
      return `X ${Math.round(x)}, Y ${Math.round(y)}`
    }
  }
}
</script>

<template lang="pug">
.position-indicator-container
  .position-indicator(v-tooltip.top="{ content: 'Position dans le Canvas', offset: '5'}") {{ positionText }}
</template>

<style lang="sass" scoped>
.position-indicator-container
  min-width: 60px
</style>
