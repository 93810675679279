<script>
import toolsList from '@/mixins/toolsList'
import ImagePopinHeader from '@/components/tableau/Images/ImagePopinHeader'
import ImagePopinGallery from '@/components/tableau/Images/ImagePopinGallery'
import ImagePopinUpload from '@/components/tableau/Images/ImagePopinUpload'

export default {
  name: 'ImagePopin',
  mixins: [toolsList],
  components: { ImagePopinHeader, ImagePopinGallery, ImagePopinUpload },
  methods: {
    closePopup () {
      this.toggleActiveToolFromValue('pan')
    },
    clickOutside (e) {
      // Close the popup only if user clicked outside of popin
      if (e.target === e.currentTarget) {
        this.closePopup()
      }
    }
  }
}
</script>

<template lang="pug">
.image-popin-container(@click="clickOutside")
  .image-popin
    image-popin-header(@close="closePopup")
    .image-popin-content
      image-popin-gallery
      image-popin-upload
</template>

<style lang="sass" scoped>
.image-popin-container
  position: absolute
  top: 0
  left: 0
  right: 0
  bottom: 0
  display: flex
  justify-content: center
  align-items: center
  background-color: rgba(0,0,0,0.3)
  z-index: 100
  .image-popin
    min-width: 600px
    width: 80%
    min-height: 400px
    height: 65%
    background-color: #fff
    border-radius: 18px
    font-size: 22px
    background-color: var(--primary)
    box-shadow: 8px 8px 18px rgba(0,0,0,0.3)
    .image-popin-content
      overflow: auto
      box-sizing: border-box
      padding: 25px
      width: 100%
      height: calc(100% - 40px)
      display: flex
      justify-content: space-between
      @media (max-width: $small)
        flex-direction: column
    @media (max-width: $small)
      min-width: 300px
    @media (max-height: $small)
      min-height: 200px
</style>