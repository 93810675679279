import { apolloClientV2 } from '@/setup/apollo.js'
const state = {
  windowSize: {
    width: 0,
    height: 0
  },
  canvasWidth: 1920,
  canvasHeight: 1080,
  isCanvasReadyToBeShow: false,
  mainMenuVisibility: false,
  mobileTemplate: false,
  showNotification: false,
  notificationContent: {
    type: 'success',
    timeout: 3000,
    content: 'Aucun contenu défini'
  },
  activeCanvas: null,
  canvasAvailable: [], // This is used as the pages list
  dataRef: null,
  viewerMode: true,
  showPages: false,
  showHelpModal: false,
  newTableau: false,
  defaultName: 'Document sans titre',
  changeCanvas: false,
  finishInit: false,
  lastSave: null,
  canvasLoader: false
}

const getters = {
  firstPage (state) {
    return JSON.parse(state.canvasAvailable[0].data).objects
  },
  pagesObjects (state) {
    const output = []
    state.canvasAvailable.map(c => JSON.parse(c.data).objects).forEach(c => c.forEach(p => {
      if (p.targetid) {
        output.push({ targetid: p.targetid })
      }
      if (p.totarget) {
        output.push({ totarget: p.totarget })
      }
    }))
    return output
  },
  targetsList (state) {
    const output = []
    const objectsListList = state.canvasAvailable.map(c => JSON.parse(c.data).objects) ?? []
    objectsListList.forEach((o, index) => {
      o.forEach(object => {
        if (object.targetid) {
          const { height, width, scaleX, scaleY, left, top } = object
          output.push({
            id: object.targetid,
            page: index + 1,
            height,
            width,
            scaleX,
            scaleY,
            left,
            top
          })
        }
      })
    })
    return output
  }
}

const actions = {
  async saveCanvas ({ state, commit }) {
    if (state.viewerMode) { return null }

    commit('Tableau/addIndicatorLoading', null, { root: true })

    if (!state.dataRef) {
      const tempSave = await apolloClientV2.mutate({
        mutation: require('@/apollo/mutations/createSalleClasseEnregistrementDossier.gql'),
        variables: {
          attrs: {
            titre: state.defaultName,
            statut: 'ACTIF'
          }
        }
      }).then(({ data }) => {
        return data.createSalleClasseEnregistrementDossier
      })
      if (tempSave.id) {
        await apolloClientV2.mutate({
          mutation: require('@/apollo/mutations/createSalleClasseEnregistrementTableauBlanc.gql'),
          variables: {
            dossier: tempSave.id,
            titre: state.defaultName,
            data: JSON.stringify(state.canvasAvailable),
            paths: state.canvasAvailable.find(e => e.image) ? ['image'] : []
          }
        }).then(({ data }) => {
          if (!data.createSalleClasseEnregistrementTableauBlanc.error && data.createSalleClasseEnregistrementTableauBlanc.id) {
            state.dataRef = { dossier: tempSave.id, tableau: { id: data.createSalleClasseEnregistrementTableauBlanc.id, data: data.createSalleClasseEnregistrementTableauBlanc.data }, proprietaire: data.createSalleClasseEnregistrementTableauBlanc.dossier.usager }
          }
        })
      }
    } else {
      await apolloClientV2.mutate({
        mutation: require('@/apollo/mutations/updateSalleClasseEnregistrementTableauBlanc.gql'),
        variables: {
          id: state.dataRef.tableau.id,
          titre: state.defaultName,
          data: JSON.stringify(state.canvasAvailable),
          paths: state.canvasAvailable.find(e => e.image) ? ['image'] : []
        }
      }).then(({ data }) => {
        if (!data?.updateSalleClasseEnregistrementTableauBlanc?.error && data?.updateSalleClasseEnregistrementTableauBlanc?.id) {
          state.dataRef = { dossier: data.updateSalleClasseEnregistrementTableauBlanc.dossier.id, tableau: { id: data.updateSalleClasseEnregistrementTableauBlanc.id, data: data.updateSalleClasseEnregistrementTableauBlanc.data }, proprietaire: data.updateSalleClasseEnregistrementTableauBlanc.dossier.usager }
        }
      })
    }

    commit('Tableau/removeIndicatorLoading', null, { root: true })
    commit('lastSaveNow')

    return null
  },
  initDataRef ({ commit }, value) {
    commit('setDefaultName', value.titre)
    commit('setDataRef', { dossier: value.dossier.id, tableau: { id: value.id, data: value.data }, proprietaire: value.dossier.usager })
    const parseData = JSON.parse(value.data)
    commit('initCanvasAvailable', parseData)
    commit('setFinishInit', true) // Deprecated: use isCanvasReadyToBeShow instead
  }
}
const mutations = {
  setFinishInit (state, payload) {
    state.finishInit = payload
  },
  changeActiveCanvas (state, payload) {
    state.changeCanvas = payload
    // // on check si on a une page qui existe pour cette id
    // if (state.canvasAvailable[payload]) {
    //   state.activeCanvas = state.canvasAvailable[payload]
    // } else {
    //   state.canvasAvailable[payload] = null
    //   state.activeCanvas = null
    // }
  },
  initCanvasAvailable (state, payload) {
    state.canvasAvailable = []
    if (payload ?? Array.isArray(payload)) {
      state.canvasAvailable = [...state.canvasAvailable, ...payload]
    }
  },
  setOrAddCanvasAvailable (state, payload) {
    const findex = state.canvasAvailable.findIndex(e => e.id === payload.id)
    if (findex >= 0) {
      // existe deja avec cette id alors on remplace
      state.canvasAvailable.splice(findex, 1, payload)
      return findex + 1
    } else {
      state.canvasAvailable.push(payload)
      return state.canvasAvailable.length + 1
    }
  },
  removeCanvasAvailable (state, idToDelete) {
    const findex = state.canvasAvailable.findIndex(e => e.id === idToDelete)
    if (findex >= 0) {
      state.canvasAvailable.splice(findex, 1)
    } else {
      console.error(`Impossible de suppr la page avec l'ID: ${idToDelete}`)
    }

    // Reset the pages ids
    state.canvasAvailable.forEach((page, index) => {
      if (page.id !== index) {
        state.canvasAvailable.splice(index, 1, { ...page, id: index + 1 })
      }
    })
  },
  updateCanvasAvailable (state, payload) {
    // on remplace le canvas à l'index X par data
    if (state.canvasAvailable?.length > 0) {
      const findex = state.canvasAvailable.findIndex(e => e && e.id === payload.id)
      if (findex >= 0) {
        if (!payload.image && state.canvasAvailable[findex].image) {
          payload.image = state.canvasAvailable[findex].image
        }
        state.canvasAvailable.splice(findex, 1, payload)
      } else {
        if (!Array.isArray(state.canvasAvailable)) {
          state.canvasAvailable = []
          state.canvasAvailable.push(payload)
        }
        console.error(`Impossible d'update la page avec l'ID: ${payload?.id}`)
      }
    } else {
      state.canvasAvailable = []
      state.canvasAvailable.push(payload)
    }
  },
  resetCanvasAvailable (state) {
    state.canvasAvailable.splice(0, state.canvasAvailable.length)
  },
  changePageVideoLink (state, { index, link }) {
    if (!(state.canvasAvailable.length >= index)) {
      console.error('Cannot add link to this page, out of range')
      return
    }
    const page = state.canvasAvailable[index]
    const newPage = { ...page, video: link }
    state.canvasAvailable.splice(index, 1, newPage)

  },
  setDefaultName (state, payload) {
    state.defaultName = payload
  },
  setNewTableau (state, payload) {
    state.newTableau = payload
  },
  setActiveCanvas (state, payload) {
    state.activeCanvas = payload
  },
  setDataRef (state, payload) {
    state.dataRef = payload
  },
  setWindowSize (state, payload) {
    state.windowSize = payload
  },
  setMainMenuVisibility (state, payload) {
    state.mainMenuVisibility = payload
  },
  setMobileTemplate (state, payload) {
    state.mobileTemplate = payload
  },
  setShowNotification (state, payload) {
    state.showNotification = payload
  },
  setNotificationContent (state, payload) {
    state.notificationContent = payload
  },
  setViewerMode (state, payload) {
    state.viewerMode = payload
  },
  setShowPages (state, payload) {
    state.showPages = payload
  },
  setShowHelpModal (state, payload) {
    state.showHelpModal = payload
  },
  setCanvasWidth (state, payload) {
    state.canvasWidth = payload
  },
  setCanvasHeight (state, payload) {
    state.canvasHeight = payload
  },
  setisCanvasReadyToBeShow (state, payload) {
    if (state.isCanvasReadyToBeShow === true && payload === true) {
      state.isCanvasReadyToBeShow = false
    }
    state.isCanvasReadyToBeShow = payload
  },
  lastSaveNow (state) {
    state.lastSave = new Date().toJSON()
  },
  setCanvasLoader (state, payload) {
    state.canvasLoader = payload
  }
}

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations
}
