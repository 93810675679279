<script>
import toolsList from '@/mixins/toolsList'

export default {
  name: 'LinkPopinTarget',
  mixins: [toolsList],
  props: {
    selected: { type: Boolean }
  },
  data: () => ({
    targetName: ''
  }),
  methods: {
    clicked () {
      if (!this.selected) {
        this.$emit('select', 'target')
      }
    },
    addTarget () {
      this.$store.commit('Tools/addLinkTarget', this.targetName)
      this.toggleActiveToolFromValue('select')
    },
    back () {
      this.targetName = ''
      this.$emit('select', null)
    }
  },
  computed: {
    alreadyExists () {
      return this.$store.getters['App/targetsList'].findIndex(t => t.id === this.targetName) !== -1
    },
    showBtn () {
      return this.targetName && !this.alreadyExists
    }
  }
}
</script>

<template lang="pug">
.link-popin-target.container(:class="{ selected }" @click="clicked")
  transition(name="fade")
    .selection(v-if="!selected")
      font-awesome-icon.icon(:icon="['fas', 'bullseye-arrow']")
      h3 Ajouter une cible
      .subtitle Une cible peut être placée dans n'importe quelle page à n'importe quel endroit
  transition(name="fade-delay")
    .adding(v-if="selected")
      font-awesome-icon.icon(:icon="['fas', 'bullseye-arrow']")
      h3 Donner un nom à la nouvelle cible
      input(
        v-model="targetName"
        type="text"
        placeholder="Entrer le nom ici"
        maxlength="19"
      )
      transition(name="fade")
        button.btn(v-if="showBtn" @click="addTarget") Ajouter la cible
      p.back(@click.prevent.stop="back") Retour
</template>

<style lang="sass" scoped>
.link-popin-target
  color: var(--primary)
  background-color: var(--secondary)
  border-radius: 0 0 18px 18px
  &.container
    position: absolute
    bottom: 0
  .adding
    input
      width: 100%
      padding: 6px 18px
      box-sizing: border-box
      text-align: center
      border-radius: 20px
      border: none
      outline: none
      box-shadow: 2px 3px 8px rgba(250, 250, 250, 0.4)
    .btn
      color: var(--secondary)
      background-color: var(--primary)
    .back
      color: white
</style>
