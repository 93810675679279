const mapStateTwoWay = (states) => {
  const output = {}
  // n: namespace, k: state key, m: mutation name

  states.forEach(stateItem => {
    const { n, k, m, p } = stateItem

    if (p === undefined) {
      output[k] = {
        get() { return this.$store.state[n][k] },
        set (val) { this.$store.commit(`${n}/${m}`, val) }
      }
    } else {
      const [p1, p2] = p.split(".")
      output[k] = {
        get() { return this.$store.state[n][p1][p2] },
        set (val) { this.$store.commit(`${n}/${m}`, val) }
      }
    }
  })

  return output
}

export default mapStateTwoWay
