
export default {
  watch: {
    '$store.state.Tools.audio.recorder' (val) {
      this.insertAudioImage(val, this.$store.state.Tools.coordToInsert)
    }
  },
  methods: {
    async insertAudioImage (audio, coord) {
      const textObjects = await this.getFabricSvgObjectFromText("Cliquez sur l'icône pour écouter.", 300)
      this.$Fabric.loadSVGFromURL('/img/icons/audio.svg', (objects, options) => {
        const group = this.$Fabric.util.groupSVGElements([...objects, ...textObjects], options)
        group.set({
          top: coord.y,
          left: coord.x
        })
        group.scale(0.1)

        group['audio'] = audio
        this.fabriCanvas.add(group)
      })
    },
  }
}

