<script>
import youtubeAndVimeoHelpers from '@/mixins/youtubeAndVimeoHelpers'

export default {
  name: 'VideoOptionsInput',
  mixins: [youtubeAndVimeoHelpers],
  props: {
    page: { required: true, type: Object },
    index: { required: true, type: Number }
  },
  data () {
    return {
      isEditing: false,
      linkInput: ''
    }
  },
  computed: {
    link () {
      return this.page?.video
    },
    isIncorrectLink () {
      return this.isEditing && this.linkInput && this.linkInput!== '' && this.getYouTubeVideoIdFromUrl(this.linkInput) === null
    },
    thumbnail () {
      return this.isEditing 
      ? this.getYoutubeThumbnail(this.getYouTubeVideoIdFromUrl(this.linkInput))
      : this.getYoutubeThumbnail(this.getYouTubeVideoIdFromUrl(this.link))
    },
    shouldDisplayPreview () {
      return (this.isEditing ? this.linkInput : this.link) && !this.isIncorrectLink && this.thumbnail
    }
  },
  methods: {
    startEditing () {
      this.linkInput = this.link
      this.isEditing = true
    },
    saveLink () {
      this.$store.commit('App/changePageVideoLink', { index: this.index, link: this.linkInput })
      this.$store.dispatch('App/saveCanvas')
      this.isEditing = false
    },
    removeLink () {
      this.$store.commit('App/changePageVideoLink', { index: this.index, link: null })
      this.$store.dispatch('App/saveCanvas')
      this.isEditing = false
    }
  }
}
</script>

<template lang="pug">
.video-options-input
  .video-input-container
    template(v-if="isEditing")
      input(v-model="linkInput" placeholder="Lien Youtube" @keypress.enter="saveLink")
      button(@click="saveLink" v-tooltip.top="{ content: 'Enregistrer ce lien', offset: '5'}")
        font-awesome-icon(:icon="['fad', 'save']")
    template(v-else)
      h2(:class="{ nourl: !link}") {{ link ? link : 'Aucune vidéo liée'}}
      button(@click="startEditing" v-tooltip.top="{ content: link ? 'Éditer le lien' : 'Ajouter un lien', offset: '5'}")
        font-awesome-icon(:icon="link ? ['fad', 'pencil'] :  ['fas', 'plus']")
      button(v-if="link" @click="removeLink" v-tooltip.top="{ content: 'Supprimer le lien', offset: '5'}")
        font-awesome-icon(:icon="['fad', 'trash-alt']")
  p.video-incorrect-link(v-if="isIncorrectLink") Lien de vidéo incorrect
  transition(name="thumbnail")
    .video-thumbnail-container(v-if="shouldDisplayPreview")
      img.youtube-thumbnail-preview(:src="thumbnail" alt="Aperçu Miniature Youtube")
</template>

<style lang="sass" scoped>
.video-options-input
  .video-input-container
    display: flex
    justify-content: space-between
    align-items: center
  h2
    font-size: 16px
    margin: 5px 0
    white-space: nowrap
    text-overflow: ellipsis
    overflow: hidden
    max-width: 100%
    &.nourl
      font-style: italic
  input
    font-size: 16px
    margin: 5px 0
    padding: 2px 6px
    border-radius: 12px
  button
    background: transparent
    border: none
  p.video-incorrect-link
    color: red
    margin: 5px 0
  .video-thumbnail-container
    display: flex
    justify-content: center
    align-items: center
    height: 100px
    padding: 5px 25px
    .youtube-thumbnail-preview
      max-width: 100%
      max-height: 100%
      border-radius: 12px

// Transitions
.thumbnail-enter-active, .thumbnail-leave-active
  transition: opacity .5s ease, transform 0.3s ease

.thumbnail-enter, .thumbnail-leave-to
  opacity: 0
  transform: translateY(-25px)

</style>
