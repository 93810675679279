<script>
import toolsList from '@/mixins/toolsList'

export default {
  name: 'LinkPopinBow',
  mixins: [toolsList],
  data: () => ({
    selectedTarget: null
  }),
  props: {
    selected: { type: Boolean }
  },
  methods: {
    clicked () {
      if (!this.selected) {
        this.$emit('select', 'bow')
      }
    },
    back () {
      this.selectedTarget = null
      this.$emit('select', null)
    },
    addBow () {
      this.$store.commit('Tools/addLinkBow', this.selectedTarget)
      this.toggleActiveToolFromValue('select')
    }
  },
  computed: {
    targets () {
      return this.$store.getters['App/targetsList']
    },
    targetsIds () {
      return this.targets.map(t => t.id)
    },
    isBtnDisabled () {
      return this.selectedTarget === null || !this.targetsIds.includes(this.selectedTarget)
    }
  }
}
</script>

<template lang="pug">
.link-popin-bow.container(:class="{ selected }" @click="clicked")
  transition(name="fade")
    .selection(v-if="!selected")
      font-awesome-icon.icon(:icon="['fas', 'bow-arrow']")
      h3 Ajouter un arc cliquable
      .subtitle L'arc sert à se téléporter jusqu'à la cible associée qui lui est associée
  transition(name="fade-delay")
    .adding(v-if="selected")
      font-awesome-icon.icon(:icon="['fas', 'bow-arrow']")
      template(v-if="targetsIds.length > 0")
        h3 Vers quelle cible doit pointer l'arc ?
        v-select(:options="targetsIds" v-model="selectedTarget" placeholder="Selectionner une cible")
        transition(name="fade")
          button.btn(v-if="!isBtnDisabled" @click="addBow") Ajouter cet arc
      template(v-else)
        h3 Vous devez avoir au moins une cible dans votre tableau afin de pouvoir créer un arc
      p.back(@click.prevent.stop="back") Retour
</template>

<style lang="sass" scoped>
.link-popin-bow
  color: var(--secondary)
  background-color: var(--primary)
  border-radius: 18px 18px 0 0
  .adding
    .v-select::v-deep
      --vs-controls-color: var(--secondary)
      --vs-border-color: var(--secondary)
      width: 100%
      font-size: 18px
      > div
        min-height: 42px
    .btn
      color: var(--primary)
      background-color: var(--secondary)
    .back
      color: black
</style>

<style lang="sass">
.vs__dropdown-menu
  max-height: 120px
</style>