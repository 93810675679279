export default {
  watch: {
    '$store.state.Tools.links': {
      deep: true,
      handler (newVal) {
        if (newVal?.targetIdToAdd) {
          this.insertLinkTarget(newVal?.targetIdToAdd)
        }
        if (newVal?.bowToAdd) {
          this.insertLinkBow(newVal?.bowToAdd)
        }
      }
    }
  },
  methods: {
    async insertLinkTarget (id) {
      const textObjects = await this.getFabricSvgObjectFromText(id, 245)
      this.$Fabric.loadSVGFromURL('/img/icons/target.svg', (targetObjects, options) => {
        const shape = this.$Fabric.util.groupSVGElements([...targetObjects, ...textObjects], options)
        shape.set({ ...this.calcLeftAndTopAsCenter(), name: id })
        shape.scale(0.2)
        shape['targetid'] = id
        this.fabriCanvas.add(shape)
      })
      this.$store.commit('Tools/addLinkTarget', null)
    },
    async insertLinkBow (id) {
      const textObjects = await this.getFabricSvgObjectFromText(id, 245)
      this.$Fabric.loadSVGFromURL('/img/icons/bow.svg', (targetObjects, options) => {
        const shape = this.$Fabric.util.groupSVGElements([...targetObjects, ...textObjects], options)
        shape.set({ ...this.calcLeftAndTopAsCenter(), name: id })
        shape.scale(0.2)
        shape['totarget'] = id
        this.fabriCanvas.add(shape)
      })
      this.$store.commit('Tools/addLinkBow', null)
    },
    calcLeftAndTopAsCenter () {
      const shapeSize = 110
      const canvasWrapper = document.querySelector('.tableau-canvas__wrapper')
      const zoom = this.$store.state.Tableau.canvasZoom
      const canvasWidth = canvasWrapper.offsetWidth / 2
      const canvasHeight = canvasWrapper.offsetHeight / 2
      const x = - this.$store.state.Tableau.canvasPosX
      const y = - this.$store.state.Tableau.canvasPosY

      return {
        left: x + canvasWidth * zoom - shapeSize / 2,
        top: y + canvasHeight * zoom - shapeSize / 2
      }

    },
    async getFabricSvgObjectFromText (text, x) {
      return new Promise((resolve) => {
        const svgString = `<svg xmlns='http://www.w3.org/2000/svg' height='496' width='496'>
          <text x='${x}' y='630' fill='black' font-size='8em' text-anchor='middle' font-family="Roboto">${text}</text></svg>`
        this.$Fabric.loadSVGFromString(svgString, (objects) => {
          resolve(objects)
        })
      })
    }
  }
}