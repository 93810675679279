<script>
import mapStateTwoWay from '@/mixins/mapStateTwoWay'

export default {
  name: 'HelpModal',
  props: {
  },
  components: {
  },
  data () {
    return {
    }
  },
  computed: {
    ...mapStateTwoWay([
      { n: 'App', k: 'showHelpModal', m: 'setShowHelpModal' },
    ])
  },
  methods: {
    toggleHelp () {
      this.showHelpModal = !this.showHelpModal
    }
  }
}
</script>

<template lang="pug">

.modal-index(v-if='showHelpModal')
  a.modal-overlay(@click='toggleHelp', :class='{"visible-overlay" : showHelpModal}')
  .modal()
    perfect-scrollbar.modal-scroller(id='pages-scroller')
      .modal-header
        span Aide
        button.close-button(@click='toggleHelp')
          font-awesome-icon(:icon="['fas', 'times']", style='font-size: 24px')
      .modal-content__wrapper
        p Les Tableaux blancs Dokoma permettent de créer des présentations pédagogiques.

        p Vous pouvez insérer
        ul
          li des images
          li du texte
          li des fonctions mathématiques
          li des formes géométriques
          li des traits
          li des vidéos Youtube
          li des liens entre les éléments
        ul

        p Merci de vous amuser avec ces outils !

        p.low-margin Prochainement
        p.low-margin Intégration de Desmos
</template>

<style lang='sass'>
@import 'src/assets/styles/base/_modal.sass'
p.low-margin
  margin-top: 5px
  margin-bottom: 5px
</style>
