
<script>
export default {
  name: "desmosOptions",
  computed: {
    getTitle () {
      return this.$store.state.Tableau.desmosTitle
    }
  }
  }
</script>
<template lang="pug">
.tool-index.text-options
  .tool-section
    section.tool-option.vertical
      div {{getTitle}}
</template>

<style lang="sass" scoped>

</style>