<script>
export default {
  name: 'DeleteOptions',
}
</script>

<template lang="pug">
.tool-index.delete-options
  .tool-section
    section.tool-option.vertical
      label.tool-option--title
        | Options de suppression (À venir)
      .tool-option--control
        button Supprimer les formes
        button Supprimer le texte
        button Supprimer les dessins
        hr
        button.main-button Tout supprimer
</template>

<style lang='sass'>
.delete-options
  .tool-option--control
    margin-top: 5px
    button
      width: 100%
      height: 20px
      margin: 3px 0
      background-color: transparent
      border: solid 1px var(--secondary)
      border-radius: 20px
      font-size: 12px
      &.main-button
        height: 40px
        font-size: 14px
        color: var(--error)
        border-color: var(--error)
</style>
