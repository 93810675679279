<script>
export default {
  name: 'ResolutionIndicator',
  computed: {
    resolutionText () {
      return `${this.$store.state.App.canvasWidth}x${this.$store.state.App.canvasHeight} px`
    }
  }
}
</script>

<template lang="pug">
.resolution-indicator-container
  .resolution-indicator(v-tooltip.top="{ content: 'Résolution du Canvas', offset: '5'}") {{ resolutionText }}
</template>

<style lang="sass" scoped>
.resolution-indicator-container
  min-width: 75px
</style>