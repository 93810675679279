<script>
import VueCookies from 'vue-cookies'
import windowSize from '@/mixins/windowSize.js'
import Home from './components/home'

export default {
  name: 'App',
  components: {
    Home
  },
  mixins: [
    windowSize
  ],
  props: {
    // dataTableau: {
    //   required: false,
    //   type: Object
    // }
  },
  watch: {
    '$store.state.User.profile': {
      deep: true,
      handler (newVal) {
        if (newVal && newVal.roles && newVal.roles.includes('eleve')) {
          this.$store.commit('App/setViewerMode', true)
        }
      }
    },
    '$store.state.App.dataRef': {
      deep: true,
      handler (newVal) {
        const temp = new URLSearchParams(window.location.search)
        const tableau = temp.get('tableau')
        if (newVal && !tableau) {
          const newurl = window.location.protocol + "//" + window.location.host + window.location.pathname + '?tableau=' + newVal.tableau.id
          window.history.pushState({ path:newurl },'',newurl)
          // window.location.href = window.location.href + '?tableau=' + newVal.tableau.id
          // window.location.search = 'tableau=' + newVal.tableau.id
        }
      }
    }
  },
  async created () {
    const url = new URL(window.location);
    const token = url.searchParams.get("token");
    if (token) {
      const dateExp = new Date()
      dateExp.setDate(dateExp.getDate() + 1)
      VueCookies.set('ClassDokomaToken', token, new Date(parseInt(dateExp.toString() + '000')), '/', '.dokoma.com')

      url.searchParams.delete("token");

      window.history.replaceState(null, "", url)
    }

    await this.$store.dispatch('User/initUser')
    const temp = new URLSearchParams(window.location.search)
    const dossier = temp.get('dossier')
    const tableau = temp.get('tableau')
    if (!this.$store.state.User.connected || !this.$store.state.User.profile) {
      this.$store.commit('App/setViewerMode', true)
    }
    if (dossier) {
      // pour l'instant dossier OSEF
    }
    if (tableau) {
      this.$store.dispatch('Tableau/initTableau', tableau)
    }
  },
  data () {
    return {
      showTest: false
    }
  },
  computed: {
  },
  mounted () {
  }
}
</script>

<template lang="pug">
#app()
  home()
</template>

<style lang='sass'>
@import 'src/assets/styles/base/_components'
@import 'src/assets/styles/vendors/_v-tooltip'
@import 'v-ripple-directive/src/index.scss'

body
  overflow: hidden
  margin: 0
  background-color: #e2fdff
#app
  font-family: $body-font-family
  width: 100vw
  height: 100vh
  height: calc(var(--vh, 1vh) * 100)
  max-height: 100vh
  max-height: calc(var(--vh, 1vh) * 100)

:root
  --tableau-color: #ffffff
  --primary: #{$primary-color}
  --secondary: #{$secondary-color}
  --success: #{$success-color}
  --error: #{$error-color}
  --bg-color: #ffffff
  // --display-font: #{$display-font}
  // --primary-font: #{$primary-font}
  // --secondary-font: #{$secondary-font}
</style>
