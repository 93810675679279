<script>
import WaveIcon from '@/components/tableau/tools/waveIcon.vue'
import { apolloClientV2 } from '@/setup/apollo.js'

import Recorder from 'recorderjs'
window.AudioContext = window.AudioContext || window.webkitAudioContext
navigator.getUserMedia = navigator.getUserMedia || navigator.webkitGetUserMedia
window.URL = window.URL || window.webkitURL
window.Recorder = Recorder
if (!window.MediaRecorder) {
  window.MediaRecorder = require('audio-recorder-polyfill')
}
export default {
  name: 'AudioComment',
  components: {
    WaveIcon
  },
  props: {
    dataBrut: { required: false, type: String, default: () => '' },
    active: { required: false, type: Boolean, default: false },
    showWave: { required: false, type: Boolean, default: true }
  },
  data () {
    return {
      activeRecording: false,
      storedRecording: null,
      savedRecording: false,
      audioContext: null,
      audioBlock: null,
      fichierSon: null,
      base64Data: null,
      urlFile: null,
      rec: null,
      loading: false,
      idTimeOut: null
    }
  },
  watch: {
    dataBrut: {
      deep: true,
      immediate: true,
      handler (newVal) {
        if (newVal) {
          this.checkDataBrut()
        }
      }
    }
  },
  methods: {
    checkDataBrut () {
      this.activeRecording = false
      this.savedRecording = false
      this.storedRecording = null
      if (this.dataBrut) {
        if (this.dataBrut.includes('https://cdn.dokoma.com')) {
          const thus = this
          fetch(this.dataBrut)
            .then(res => res.blob())
            .then((dataBrut) => {
              thus.urlFile = URL.createObjectURL(dataBrut)
            })
        } else {
          this.urlFile = this.dataBrut
        }
      } else {
        this.urlFile = null
      }
    },
    updateComment () {
      this.$emit('sendAudio')
    },
    startRecording () {
      this.activeRecording = true
      this.urlFile = null
      this.audioContext = new AudioContext()
      const thus = this
      this.fichierSon = null
      if (!navigator.getUserMedia) {
        navigator.mediaDevices.getUserMedia({ audio: { channelCount: 1 } }).then((stream) => {
          thus.audioBlock = stream
          const src = thus.audioContext.createMediaStreamSource(stream)
          thus.rec = new Recorder(src, { numChannels: 1 })
          thus.rec.record()
          thus.idTimeOut = setTimeout(thus.stopRecording, 46000)
        })
      } else {
        navigator.getUserMedia({ audio: true }, function (stream) {
          thus.audioBlock = stream
          const src = thus.audioContext.createMediaStreamSource(stream)
          thus.rec = new Recorder(src, { numChannels: 1 })
          thus.rec.record()
          thus.idTimeOut = setTimeout(thus.stopRecording, 46000)
        }, function (error) {
          console.error(error)
        })
      }
    },
    stopRecording () {
      if (this.idTimeOut) {
        clearTimeout(this.idTimeOut)
      }
      if (this.rec) {
        let base64data = null
        const thus = this
        this.rec.stop()
        this.audioBlock.getAudioTracks()[0].stop()
        this.rec.exportWAV(function (blob) {
          thus.urlFile = URL.createObjectURL(blob)
          const reader = new FileReader()
          reader.readAsDataURL(blob)
          reader.onloadend = function () {
            thus.base64Data = reader.result
            base64data = reader.result.split(',')[1]
            thus.storedRecording = base64data
          }
          thus.rec.clear()
        }, 'audio/wav')
        this.activeRecording = false
      }
    },
    async saveRecording () {
      // add function to save audio on back
      const urlFile = await this.createAudio()
      if (urlFile) {
        this.$store.commit('Tools/setRecorder', urlFile)
        this.savedRecording = true
        this.storedRecording = null
        this.$store.commit('Tools/setShowAudioPreview', false)
      }
    },
    cancelSave () {
      this.savedRecording = false
    },
    async createAudio() {
      this.loading = true
      try {
        const { data } = await apolloClientV2.mutate({
          mutation: require('@/apollo/mutations/createCommunFichier.gql'),
          variables: {
            fichiable: {
              type: "SALLECLASSE_ENREGISTREMENT_TABLEAUBLANC",
              id: this.$store.state.App.dataRef.tableau.id
              }, 
            data: this.base64Data
          }
        })
        if (data?.createCommunFichier) {
          this.loading = false
          return data.createCommunFichier
        }
      } catch (error) {
        console.log(error)
      }
    }
  }
}
</script>
<template lang="pug">
.audio-comment__wrapper(:class='{"disabled" : !active, "hide-wave" : !showWave}')
  template(v-if='!loading')
    //- span ACTIVE OU PAS {{active}}
    .audio-comment(v-if='active')
      template(v-if='!savedRecording')
        template(v-if='storedRecording && !activeRecording')
          div.waveform(v-if='urlFile')
            av-waveform(:audio-src='urlFile', :canv-width='250', :canv-height='50', noplayed-line-color='rgb(61, 44, 57)', played-line-color='rgb(252, 96, 85)')

        template(v-if='!activeRecording')
          button(rounded, block, depressed, :class='storedRecording ? "secondary" : "positive"', small, @click='startRecording()', :outlined='!!storedRecording') {{storedRecording ? "Recommencer l'enregistrement" : 'Démarrer un enregistrement'}}
          button(v-if='storedRecording', rounded, block, depressed, color='positive', @click='saveRecording()') Sauvegarder l'enregistrement

          div(v-if='!storedRecording') Vous pourrez valider le contenu avant de sauvegarder
        template(v-else-if='activeRecording')
          div
            div Enregistrement en cours
            av-media(:media='audioBlock', :canv-width='250', :canv-height='50', line-color='rgb(61, 44, 57)')
          button.mb1(rounded, block, depressed, color='error', @click='stopRecording()') Terminer l'enregistrement
    .audio-comment(v-else-if='urlFile')
      div.waveform
        av-waveform(:audio-src='urlFile', :canv-width='250', :canv-height='50', noplayed-line-color='rgb(61, 44, 57)', played-line-color='rgb(252, 96, 85)')
  .loading(v-else)
    font-awesome-icon.fa-spin(:icon="['fas', 'spinner']")
</template>
<style lang='sass'>
.audio-comment__wrapper
  overflow: hidden
  .loading
    display: flex
    justify-content: center
    align-iems: center
    svg
      width: 40px
      height: 40px
  .audio-comment
    padding: 10px 10px
    display: flex
    flex-direction: column
    align-items: center
    button
      width: 240px
      background-color: var(--success)
      color: white
      padding: 10px
      border-radius: 5px
      margin-bottom:5px
    .secondary
      background-color: var(--error) !important
    .waveform
      div
        display: flex
        flex-direction: column-reverse
        audio
          width: 100%
  &.hide-wave
    .audio-comment
      .waveform
        canvas
          display: none
</style>
