<script>
import ArrowOptionsPreview from '@/components/tableau/tools/arrowOptionsPreview'
import ArrowOptionsSelection from '@/components/tableau/tools/arrowOptionsSelection'
import ArrowOptionsColor from '@/components/tableau/tools/arrowOptionsColor'

export default {
  name: 'ArrowOptions',
  components: { ArrowOptionsPreview, ArrowOptionsSelection, ArrowOptionsColor}
}
</script>

<template lang="pug">
.tool-index.arrow-options
  arrow-options-preview
  arrow-options-selection
  arrow-options-Color
</template>

<style lang='sass' scoped>
.arrow-options
  position: relative
</style>
