export default {
  methods: {
    createCanvasObject ({ overridId, overridCanvas, overridImage, overridVideo, useDefaultGridProperties } = {}) {
      // This method will be usefull to keep versionning of the Canvas object,
      // As its structure might change over time

      // Versions details :
      // No version specified: Just using id, data and image fields. With nothing more than fabric data in "data" field
      // v1 : Adding properties about grid (background color, shapes, ...) and videos

      // id, data, image, video
      const id = overridId !== undefined ? overridId : this.$store.state.Tableau.activePage
      const fabricCanvas = overridCanvas !== undefined ? overridCanvas : this.$store.state.App.activeCanvas

      const data = JSON.stringify(overridCanvas !== undefined ? fabricCanvas : fabricCanvas?.toJSON(['targetid', 'totarget', 'custom', 'audio']))
      const video = overridVideo !== undefined ? overridVideo : (this.$store.getters['Tableau/activeLink'] ?? null)
      // Grid properties (background)
      const { activeGrid, gridColor, gridOpacity, tableauBgColor } = this.$store.getters['Tableau/gridProperties']
      const image = overridImage !== undefined ? overridImage : this.getCroppedScreenshot(fabricCanvas, tableauBgColor)
      const defaultGridValues = { activeGrid: null, gridOpacity: 0.25, gridColor: '#000000', tableauBgColor: '#ffffff', fontSelected: 'Red Hat Text' }
      const fontSelected = this.$store.state.Tools.text.fontSelected
      const gridProperties = useDefaultGridProperties ? defaultGridValues : { activeGrid, gridColor, gridOpacity, tableauBgColor, fontSelected }
      const zoomPosCoord = { zoom: this.$store.state.App.activeCanvas.viewportTransform[3], top: this.$store.state.App.activeCanvas.viewportTransform[4], left: this.$store.state.App.activeCanvas.viewportTransform[5] }

      return { dokoma_version: 1, id, data, image, gridProperties, video, zoomPosCoord }
    },
    getCroppedScreenshot (fabricCanvas, bg = '#ffffff') {
      if (!fabricCanvas) {
        return null
      }
      // Use bgc from store to take jpeg screenshot with this color
      // Then set it back to transparent
      fabricCanvas.backgroundColor = bg
      fabricCanvas.renderAll()
      const canvasWrapper = document.querySelector('.tableau-canvas__wrapper')
      const wrapperWidth = canvasWrapper?.offsetWidth ?? 1920
      const wrapperHeight = canvasWrapper?.offsetHeight ?? 1080
      const { width, height } = fabricCanvas
      const leftMargin = Math.floor((width - wrapperWidth) / 2)
      const topMargin = Math.floor((height - wrapperHeight) / 2)
      const imgOptions = {
        format: 'jpeg',
        quality: 0.8,
        multiplier: 0.5,
        left: leftMargin,
        top: topMargin - topMargin,
        width: wrapperWidth,
        height: wrapperHeight,
      }
      const jpegDataBase64 = fabricCanvas?.toDataURL(imgOptions)

      // Set the canvas bgc back to transparent
      fabricCanvas.backgroundColor = 'transparent'
      fabricCanvas.renderAll()
      return jpegDataBase64
    },
    applyGridProperties () {
      const activePage = this.$store.state.Tableau.activePage
      const activeCanvasAvailable = this.canvasAvailable.find(e => e.id === activePage)

      if (activeCanvasAvailable?.dokoma_version >= 1) {
        const { activeGrid, gridColor, gridOpacity, tableauBgColor } = activeCanvasAvailable.gridProperties
        this.$store.dispatch('Tableau/setGridProperties', { activeGrid, gridColor, gridOpacity, tableauBgColor })
        const fontSelected = activeCanvasAvailable.gridProperties.fontSelected ? activeCanvasAvailable.gridProperties.fontSelected : 'Red Hat Text'
        this.$store.dispatch('Tools/setFont', fontSelected)
      }
    },
    editZoomPos (val) {
      this.fabriCanvas.setZoom(val?.zoomPosCoord?.zoom ? val.zoomPosCoord.zoom : 1)
      this.fabriCanvas.absolutePan({ x: val?.zoomPosCoord?.top ? -val.zoomPosCoord.top : 0, y: val?.zoomPosCoord?.top ? -val.zoomPosCoord.left : 0 })
    },
    onPageChange (newPageNumber) {
      // /!\ First page is Page=1, not Page=0

      const findNewCanvas = this.canvasAvailable.find(e => e.id === newPageNumber)
      if (findNewCanvas) {
        if (findNewCanvas.data) {
          this.fabriCanvas.loadFromJSON(findNewCanvas.data)
          this.applyGridProperties()
          this.editZoomPos(findNewCanvas)
        } else {
          this.fabriCanvas.clear()
        }
      } else {
        const pageId = this.canvasAvailable?.length + 1
        const newCanvas = this.createCanvasObject({
          overridId: pageId,
          overridCanvas: { objects: [] },
          overridImage: null,
          overridVideo: null,
          useDefaultGridProperties: true
        })
        this.fixMathFormulaTooSmall()
        this.$store.commit('App/setOrAddCanvasAvailable', newCanvas)
        this.fabriCanvas.clear()
        this.fabriCanvas.renderAll()
      }

      // Be sure that the selected tools still applies on the new page
      const activeTool = this.$store.state?.Tools?.activeTool
      this.onActiveToolChange(activeTool)

      const shouldGoToThisTarget = this.$store.state.Tools.links.targetToGoAfterPageChange
      if (shouldGoToThisTarget) {
        this.goToTarget(shouldGoToThisTarget)
      }

      this.fixMathFormulaTooSmall()
      // Ugly fix, in case the precedent line did not happen
      setTimeout(() => {
        if (newPageNumber === this.$store.state.Tableau.activePage) {
          this.fixMathFormulaTooSmall()
        }
      }, 1500)
    },
    onActiveToolChange (newVal) {
      if (newVal === undefined) {
        // hardcoded from "ToolsList mixins first element"
        newVal = { title: 'Se déplacer', value: 'pan', icon: { type: 'fas', name: 'hand-paper' }, cursor: 'grab', hoverCursor: 'grab', hideOptions: true }
      }
      // default state
      this.fabriCanvas.selectionColor = 'rgba(0,0,0,0)'
      this.changeSelectableStatus(true)
      this.setDrawingMode(false)
      this.eraseMode = false
      this.editMath = false
      this.panAvailable = false
      this.changeDefaultCursor(newVal?.cursor)

      const tool = newVal.value
      if (tool === 'pan' || tool === 'video') {
        this.changeSelectableStatus(false)
        this.panAvailable = true
      } else if (tool === 'draw') {
        this.setDrawingMode(true)
      } else if (tool === 'erase') {
        this.eraseMode = true
      } else if (tool === 'text') {
        this.selectedText = true
      } else if (tool === 'shapes') {
        this.setShapeMode(true)
      } else {
        this.fabriCanvas.selectionColor = 'rgba(0,94,255,0.25)'
      }

      // Action when changing tools
      this.fabriCanvas.discardActiveObject()
    },
    attachEvents () {
      this.fabriCanvas.on('after:render', () => {
        const x = this.fabriCanvas.viewportTransform[4]
        const y = this.fabriCanvas.viewportTransform[5]
        this.$store.commit('Tableau/setCanvasPosition', { x, y })
        this.$store.commit('Tableau/setCanvasZoom', this.fabriCanvas.getZoom())
      })
      this.fabriCanvas.on('selection:updated', (event) => {
        this.$store.commit('Tableau/setElementSelected', event.target)
        if (event && event.e) {
          const mouse = this.fabriCanvas.getPointer(event.e)
          this.checkSelection(event.target, { x: event.e.clientX, y: event.e.clientY }, { x: mouse.x, y: mouse.y })
        }
      })
      this.fabriCanvas.on('selection:created', (event) => {
        this.$store.commit('Tableau/setElementSelected', event.target)
        if (event && event.e) {
          const mouse = this.fabriCanvas.getPointer(event.e)
          this.checkSelection(event.target, { x: event.e.clientX, y: event.e.clientY }, { x: mouse.x, y: mouse.y })
        }
      })
      this.fabriCanvas.on('object:added', (obj) => {
        if (this.fabriCanvas.isDrawingMode) {
          obj.target.set(this.$store.state.Tools.optionsBoundingBox)
        }
        this.updateStoreCanvas()
      })
      this.fabriCanvas.on('object:removed', () => {
        console.debug('on object remove')
        this.updateStoreCanvas()
      })
      this.fabriCanvas.on('object:moved', () => {
        this.updateStoreCanvas()
      })
      this.fabriCanvas.on('object:modified', () => {
        this.updateStoreCanvas()
      })

      // Zoom au scroll de la molette souris
      this.fabriCanvas.on('mouse:wheel', (opt) => {
        if (opt && opt.e) {
          let delta = opt.e.deltaY
          let zoom = this.fabriCanvas.getZoom()
          zoom *= 0.999 ** delta
          if (zoom > 20) zoom = 20 // zoom max
          if (zoom < 0.01) zoom = 0.01 // zoom min
          this.fabriCanvas.zoomToPoint({ x: opt.e.offsetX, y: opt.e.offsetY }, zoom)
          opt.e.preventDefault()
          opt.e.stopPropagation()
        }
      })
      this.fabriCanvas.on('mouse:over', (opt) => {
        if (this.activeTool?.value == 'pan' && opt.target) {

          this.changeSelectableStatus(false)
        }
        // on va effacer les boundingBox affiche
        this.fabriCanvas.contextContainer.clearRect(0, 0, this.fabriCanvas.width, this.fabriCanvas.height)
        this.fabriCanvas.renderAll()
        if (this.activeTool && this.activeTool.value === 'erase' && opt.target) {
          const boundingBox = opt.target.getBoundingRect()


          this.fabriCanvas.contextContainer.strokeStyle = 'red'
          this.fabriCanvas.contextContainer.strokeRect(
            boundingBox.left + 0.5,
            boundingBox.top + 0.5,
            boundingBox.width,
            boundingBox.height
          )
          // on va afficher la bounding box
          opt.target.getBoundingRect()
        } else if (this.activeTool && this.activeTool.value === 'desmos' && opt.target?.custom?.type === 'desmos') {
          const boundingBox = opt.target.getBoundingRect()
          this.fabriCanvas.contextContainer.strokeStyle = 'blue'
          const hoverCursor = this.activeTool?.hoverCursor
          this.changerUpperCanvasCursor(hoverCursor)
          this.$store.commit('Tableau/setDesmosTitle', 'Cliquer pour modifier ce graph')
          this.fabriCanvas.contextContainer.strokeRect(
            boundingBox.left + 0.5,
            boundingBox.top + 0.5,
            boundingBox.width,
            boundingBox.height
          )
        }
        // to display desmos Expressions on viewerMode (Élève)
        if (this.$store.state.App.viewerMode && opt.target?.custom?.type === 'desmos') {
          const boundingBox = opt.target.getBoundingRect()
          this.fabriCanvas.contextContainer.strokeStyle = 'blue'
          this.fabriCanvas.contextContainer.strokeRect(
            boundingBox.left + 0.5,
            boundingBox.top + 0.5,
            boundingBox.width,
            boundingBox.height
          )
          let cursorText = document.createElement("div")
          cursorText.setAttribute("id", "Desmos__wrapper");
          cursorText.textContent = "Cliquez ici pour afficher les fonctions Desmos"
          cursorText.style.position = "absolute"
          cursorText.style.left = `${opt.e.clientX}px`
          cursorText.style.top = `${opt.e.clientY}px`
          cursorText.style.zIndex = "10"
          cursorText.style.background = "#00063D"
          cursorText.style.width = "155px"
          cursorText.style.borderRadius = "4px"
          cursorText.style.padding = "8px"
          cursorText.style.fontFamily = "Arial, sans-serif"
          cursorText.style.fontSize = "12px"
          cursorText.style.color = "white"
          document.body.appendChild(cursorText)
        }

      })
      this.fabriCanvas.on('mouse:out', () => {
        if (this.activeTool && this.activeTool.value === 'desmos') {
          this.$store.commit('Tableau/setDesmosTitle', 'Veuillez cliquer là où vous désirez insérer votre graph.')
        }
        this.fabriCanvas.contextContainer.clearRect(0, 0, this.fabriCanvas.width, this.fabriCanvas.height)
        this.fabriCanvas.renderAll()
        let cursorText = document.getElementById("Desmos__wrapper")
        if (cursorText) {
          document.body.removeChild(cursorText)
          cursorText = null
        }
      })
      this.fabriCanvas.on('mouse:down', (opt) => {
        if (!opt.target) {
          this.$store.commit('Tableau/setElementSelected', null)
        }
        if (opt.target?.totarget && this.activeTool?.value === 'pan') {
          this.goToTarget(opt.target.totarget)
        }
        if (this.activeTool?.value === 'pan' && opt?.target?.audio) {
          this.startAudio(opt.target.audio)
        }
        const pointer = this.fabriCanvas.getPointer(opt.e)
        this.panX = pointer.x
        this.panY = pointer.y
        if (!this.panAvailable) {
          if (this.activeTool?.value) {
            if (!this.fabriCanvas.getActiveObject() && this.activeTool.value === 'text') {
              this.setTextArea(this.panX, this.panY)
            } else if (this.fabriCanvas.getActiveObject() && this.activeTool.value === 'text') {
              this.$store.commit('Tools/setFontSelected', opt.target.fontFamily)
              this.$store.commit('Tools/setTextBackgroundColor', opt.target.textBackgroundColor)
              this.$store.commit('Tools/setFontSize', opt.target.fontSize)
              this.$store.commit('Tools/setTextAlign', opt.target.textAlign)
              this.changeTextElem(this.fabriCanvas.getActiveObject())
            } else if (this.activeTool.value === 'math' && !this.editMath) {
              this.editMath = true
              this.setMathField({ x: opt.e.clientX, y: opt.e.clientY }, { x: pointer.x, y: pointer.y })
            } else if (!this.fabriCanvas.getActiveObject() && this.activeTool.value === 'shapes' && !this.drawingShape) {
              this.drawingShape = true
              this.drawActiveShape(pointer.x, pointer.y)
            } else if (!this.fabriCanvas.getActiveObject() && this.activeTool.value === 'arrow' && !this.drawingArrow) {
              this.drawingArrow = true
              this.setActiveArrowHead(pointer.x, pointer.y)
            } else if (this.activeTool.value === "desmos") {
              this.$store.commit('Tools/setDesmosExpressions', opt.target?.custom?.desmosExpressions)
              this.$store.commit('Tools/setCoordToInsert', { x: pointer.x, y: pointer.y })
              this.$store.commit('Tools/setShowDesmosPreview', true)
            } else if (this.activeTool.value === "audio") {
              this.$store.commit('Tools/setCoordToInsert', { x: pointer.x, y: pointer.y })
              this.$store.commit('Tools/setShowAudioPreview', true)
            }
          }
        } else {
          this.panX = opt.e.clientX ?? pointer.x
          this.panY = opt.e.clientY ?? pointer.y
          this.panMode = true
        }
        // Set dragging cursor with pan tool or video tool
        if (this.activeTool.value === 'pan' || this.activeTool.value === 'video') {
          this.changerUpperCanvasCursor("grabbing")
        }

        //Display Desmos Expressions for Élève
        if (this.$store.state.App.viewerMode && opt.target?.custom?.type === 'desmos') {
          this.$store.commit('Tools/setDesmosExpressions', opt.target?.custom?.desmosExpressions)
          this.$store.commit('Tools/setCoordToInsert', { x: pointer.x, y: pointer.y })
          this.$store.commit('Tools/setShowDesmosPreview', true)
        }

      })
      this.fabriCanvas.on('mouse:up', (opt) => {
        // if (opt.button === 3) {
        //   this.panMode = false
        // } else
        if (this.drawingShape) {
          this.drawingShape = false
          this.resetShape()
        }
        if (this.drawingArrow) {
          const pointer = this.fabriCanvas.getPointer(opt.e)
          if (this.$store.state.Tools.arrow.start === 'circle') {
            this.cercleArrowEnd(pointer.x, pointer.y)
          } else if (this.$store.state.Tools.arrow.start === 'caret') {
            this.caretArrowStart(pointer.x, pointer.y)
          }
          if (this.$store.state.Tools.arrow.end === 'circle') {
            this.cercleArrowStart(pointer.x, pointer.y)
          } else if (this.$store.state.Tools.arrow.end === 'caret') {
            this.caretArrowEnd(pointer.x, pointer.y)
          }
          this.setGroup()
          this.resetArrow()
        }
        if (this.panAvailable) {
          this.panMode = false
        }
        this.applyHoverCursorFromActiveTool()
      })
      this.fabriCanvas.on('mouse:move', (opt) => {
        if (opt.e.type === 'touchmove') {
          const pointer = this.fabriCanvas.getPointer(opt.e)
          let x = opt.e.clientX ?? pointer.x
          let y = opt.e.clientY ?? pointer.y
          const calcDeltaX = (x - this.panX) * this.fabriCanvas.getZoom()
          const calcDeltaY = (y - this.panY) * this.fabriCanvas.getZoom()
          const deltaX = Math.abs(calcDeltaX - this.beforeLastRelativePanValues.x) < 1 ? 0 : calcDeltaX
          const deltaY = Math.abs(calcDeltaY - this.beforeLastRelativePanValues.y) < 1 ? 0 : calcDeltaY
          this.fabriCanvas.relativePan({ x: deltaX, y: deltaY })

          // Save last 2 pans to avoid flickering on touchscreen
          this.beforeLastRelativePanValues = this.lastRelativePanValues
          this.lastRelativePanValues = { x: deltaX, y: deltaY }

          this.fabriCanvas.renderAll()
          this.panX = x
          this.panY = y
        } else {
          if (opt.target && opt.e.buttons === 0) {
            // An object is on hover and mouse button is not pressed
            const tool = this.activeTool.value
            if (this.activeTool.value === 'desmos' && opt.target.custom.type === 'desmos') {
              this.applyHoverCursorFromActiveTool()
            } else if (this.activeTool.value !== 'desmos') {
              this.applyHoverCursorFromActiveTool()
            }
            if (opt.target.totarget && tool === 'pan') {
              this.changerUpperCanvasCursor('pointer')
            }
          }
          const pointer = this.fabriCanvas.getPointer(opt.e)
          if (this.panMode) {
            let x = opt.e.clientX ?? pointer.x
            let y = opt.e.clientY ?? pointer.y
            const calcDeltaX = x - this.panX
            const calcDeltaY = y - this.panY
            const deltaX = Math.abs(calcDeltaX - this.beforeLastRelativePanValues.x) < 1 ? 0 : calcDeltaX
            const deltaY = Math.abs(calcDeltaY - this.beforeLastRelativePanValues.y) < 1 ? 0 : calcDeltaY
            this.fabriCanvas.relativePan({ x: deltaX, y: deltaY })

            // Save last 2 pans to avoid flickering on touchscreen
            this.beforeLastRelativePanValues = this.lastRelativePanValues
            this.lastRelativePanValues = { x: deltaX, y: deltaY }

            this.fabriCanvas.renderAll()
            this.panX = x
            this.panY = y
          } else if (this.drawingShape) {
            const pointer = this.fabriCanvas.getPointer(opt.e)
            this.updateActiveShape(pointer.x, pointer.y)
          } else if (this.drawingArrow) {
            const pointer = this.fabriCanvas.getPointer(opt.e)
            this.drawingArrowOnMouseMove(pointer.x, pointer.y)
          }
        }
        let cursorText = document.getElementById("Desmos__wrapper")
        if (cursorText) {
          cursorText.style.left = `${event.pageX - 30}px`
          cursorText.style.top = `${event.pageY + 20}px`
        }
      })
      this.fabriCanvas.on('mouse:dblclick', (e) => {
        if (!e.target || this.activeTool.value !== 'select') {
          return
        }
        const type = e.target.type

        if (type === 'textbox') {
          this.$store.commit('Tools/setTextBackgroundColor', e.target.textBackgroundColor)
          this.$store.commit('Tools/setFontSize', e.target.fontSize)
          this.$store.commit('Tools/setTextAlign', e.target.textAlign)
          this.$store.commit('Tools/setFontSelected', e.target.fontFamily)
          this.toggleActiveToolFromValue('text')
        }
        if (type === 'path') {
          this.toggleActiveToolFromValue('draw')
        }
        if (type === 'circle' || type === 'rect' || type === 'triangle') {
          this.toggleActiveToolFromValue('shapes')
          this.$store.commit('Tools/setActiveShape', type === 'rect' ? 'rectangle' : type)
        }
      })
      const activeTool = this.$store.state?.Tools?.activeTool
      activeTool ? this.onActiveToolChange(activeTool) : this.onActiveToolChange()
      // Activate Pan tool by default on page load
      this.applyGridProperties() // Apply grid, color, shape, etc
    },
    applyHoverCursorFromActiveTool () {
      const hoverCursor = this.activeTool?.hoverCursor
      if (hoverCursor) {
        // this.fabriCanvas.hoverCursor = hoverCursor
        this.changerUpperCanvasCursor(hoverCursor)
      }
    },
    goToTarget (targetId) {
      const target = this.$store.getters['App/targetsList'].find(t => t.id === targetId)
      if (!target) {
        console.error(`Cannot find the target ${targetId}`)
        return
      }

      if (this.activePage === target.page) {
        this.$store.commit('Tools/addTargetToDoAfterPageChange', null)
      } else {
        this.$store.commit('Tools/addTargetToDoAfterPageChange', targetId)
        this.goToPage(target.page)
        return
      }

      const canvasWrapper = document.querySelector('.tableau-canvas__wrapper')
      const zoom = this.$store.state.Tableau.canvasZoom
      const xcoord = canvasWrapper.offsetWidth / 2
      const ycoord = canvasWrapper.offsetHeight / 2
      const offsetX = target.width / 2 * target.scaleX
      const offsetY = target.height / 2 * target.scaleY

      this.fabriCanvas.absolutePan({
        x: target.left * zoom - xcoord + offsetX,
        y: target.top * zoom - ycoord + offsetY
      })
    },
    onCanvasCreated () {
      console.log('canvas created')
      this.fixMathFormulaTooSmall()
    },
    fixMathFormulaTooSmall () {
      // Fix Math rendered so small they seem to be deleted
      this.fabriCanvas.getObjects().forEach(object => {
        if (object?.custom?.type === 'math') {
          object._objects?.forEach(symbol => {
            symbol.scale(0.0022627)
          })
        }
      })
      this.fabriCanvas.renderAll()
    },
    startAudio (audio) {
      this.$store.commit('Tools/setRecorderToLire', audio.fichierUrl)
      this.$store.commit('Tools/setAudioWave', true)
    }
  }
}