// Based on this blog post : https://blog.devgenius.io/how-to-extract-the-id-of-a-youtube-or-vimeo-url-with-javascript-ad5e2d1049a

export default {
  methods: {
    // Return null if no id is found
    getYouTubeVideoIdFromUrl (url) {
      if (!url) {
        return null
      }
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      const match = url.match(regExp)
      return match && match[2].length === 11 ? match[2] : null
    },
    getVimeoIdFromUrl (url) {
      const match = /vimeo.*\/(\d+)/i.exec(url)
      return match ? match[1] : null
    },
    generateYouTubeUrl (videoId) {
      return `//www.youtube.com/embed/${videoId}?playlist=${videoId}&autoplay=1&rel=0`;
    },
    generateVimeoUrl (videoId) {
      return `https://player.vimeo.com/video/${videoId}?&autoplay=1&loop=1&title=0&byline=0&portrait=0&muted=1&#t=235s`;
    },
    getYoutubeThumbnail (videoId) {
      if (!videoId) {
        return null
      }
      return `https://i3.ytimg.com/vi/${videoId}/hqdefault.jpg`
    }
  }
}
