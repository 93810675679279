import { v4 as uuidv4 } from 'uuid'
export default {
  data () {
    return {
      textElem: null
    }
  },
  watch: {
    '$store.state.Tools.text.fontSelected': {
      deep: true,
      handler (newVal) {
        if (newVal && this.textElem) {
          this.textElem.set({ fontFamily: newVal })
          this.fabriCanvas.renderAll()
        }
      }
    },
    '$store.state.Tool.text.fontSize': {
      deep: true,
      handler (newVal) {
        this.textElem({ fontSize: newVal })
        this.fabriCanvas.renderAll()
      }
    }
  },
  methods: {
    changeTextElem (val) {
      this.textElem = val
    },
    changeFontSize () {
      console.log('CHANGE FONT SIZE')
    },
    setTextArea (x, y) {
      this.textElem = new this.$Fabric.Textbox('Insérer du texte', {
        id: uuidv4(),
        top: y,
        left: x,
        editable: true,
        fontSize: this.$store.state.Tools.text.fontSize,
        textBackgroundColor: this.$store.state.Tools.text.textBackgroundColor,
        scaleX: 2,
        scaleY: 2,
        width: 200,
        // fontWeight: 'bold'
        // fontStyle: 'italic',
        fontFamily: this.$store.state.Tools.text.fontSelected,
        fontWeight: this.$store.state.Tools.text.fontWeight,
        fontStyle: this.$store.state.Tools.text.fontStyle,
        textAlign: this.$store.state.Tools.text.textAlign,
        color: '#3171d8',
      })
      this.textElem.set(this.$store.state.Tools.optionsBoundingBox)
      this.textElem['custom'] = { type: 'text' }
      this.fabriCanvas.add(this.textElem)
      this.fabriCanvas.renderAll()
      this.fabriCanvas.setActiveObject(this.textElem)
      this.textElem.enterEditing()
      this.textElem.selectAll()
      this.$store.commit('Tableau/setSelection', this.textElem)
      // this.textElem.select()
    }
  }
}
