import { render, staticRenderFns } from "./ImagePopinImgPreview.vue?vue&type=template&id=6b59ea52&scoped=true&lang=pug&"
import script from "./ImagePopinImgPreview.vue?vue&type=script&lang=js&"
export * from "./ImagePopinImgPreview.vue?vue&type=script&lang=js&"
import style0 from "./ImagePopinImgPreview.vue?vue&type=style&index=0&id=6b59ea52&lang=sass&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6b59ea52",
  null
  
)

export default component.exports