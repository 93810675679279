import { v4 as uuidv4 } from 'uuid'

export default {
  data () {
    return {
      origX: null,
      origY: null,
      line: null,
      cercleHead: null,
      caretHead: null,
      cercleHeadStart: null,
      caretHeadStart: null
    }
  },
  methods: {
    resetArrow () {
      this.drawingArrow = false
      this.origY = null
      this.origX = null
      this.line = null
      this.cercleHead = null,
        this.caretHead = null,
        this.cercleHeadStart = null,
        this.caretHeadStart = null
    },
    setActiveArrowHead (x, y) {
      this.origX = x
      this.origY = y
      // let linePath = 'M ' + this.origX  + ' ' + this.origY + ' L ' + this.origX  + ' ' + this.origY
      this.line = new this.$Fabric.Line([this.origX, this.origY, this.origX, this.origY], {
        stroke: this.$store.state.Tools.arrow.color,
        strokeWidth: 3,
        hasControls: false,
        hasBorders: false,
        objectCaching: false,
      })
    },
    drawingArrowOnMouseMove (x, y) {
      if (this.line) {
        this.line.set({
          x2: x,
          y2: y
        }
        )
      }
    },
    caretArrowEnd (x, y, isStart) {
      if (this.isValid(x, y)) {
        let arrowHearPath = 'M 0 0 L 20 10 L 0 20 Z'
        this.caretHead = new this.$Fabric.Path(arrowHearPath, {
          fill: this.$store.state.Tools.arrow.color,
          stroke: '',
          originX: 'center',
          originY: 'center',
          hasControls: false,
          hasBorders: false,
          top: isStart ? this.origY : y,
          left: isStart ? this.origX : x,
          angle: isStart ? this.calcArrowAngle(this.origX, this.origY, x, y) - 180 : this.calcArrowAngle(this.origX, this.origY, x, y),
        })
      }
    },
    caretArrowStart (x, y) {
      if (this.isValid(x, y)) {
        let arrowHearPath = 'M 0 0 L 20 10 L 0 20 Z'
        this.caretHeadStart = new this.$Fabric.Path(arrowHearPath, {
          fill: this.$store.state.Tools.arrow.color,
          stroke: '',
          originX: 'center',
          originY: 'center',
          hasControls: false,
          hasBorders: false,
          top: this.origY,
          left: this.origX,
          angle: this.calcArrowAngle(this.origX, this.origY, x, y) - 180
        })
      }
    },
    cercleArrowEnd (x, y) {
      if (this.isValid(x, y)) {
        this.cercleHead = new this.$Fabric.Circle({
          id: uuidv4(),
          left: this.origX - 10,
          top: this.origY - 10,
          originX: 'left',
          originY: 'top',
          radius: 6,
          fill: this.$store.state.Tools.arrow.color,
          stroke: '',
          strokeWidth: 10
        })
      }
    },
    cercleArrowStart (x, y) {
      if (this.isValid(x, y)) {
        this.cercleHeadStart = new this.$Fabric.Circle({
          id: uuidv4(),
          left: x - 10,
          top: y - 10,
          originX: 'left',
          originY: 'top',
          radius: 6,
          fill: this.$store.state.Tools.arrow.color,
          stroke: '',
          strokeWidth: 10
        })
      }
    },
    setGroup () {
      var groupList = [this.line, this.cercleHead, this.cercleHeadStart, this.caretHeadStart, this.caretHead].filter((el) => {
        return el != null
      })
      var group = new this.$Fabric.Group(groupList, {
        useSetOnGroup: false
      })
      this.fabriCanvas.add(group)
      this.fabriCanvas.requestRenderAll()
      this.$store.commit('Tableau/setSelection', group)
    },
    isValid (x, y) {
      let b = (this.origX === x && this.origY === y) ? false : true
      return b
    },
    calcArrowAngle (x1, y1, x2, y2) {
      let angle = 0, x = 0, y = 0

      x = (x2 - x1);
      y = (y2 - y1);

      if (x === 0) {
        angle = (y === 0) ? 0 : (y > 0) ? Math.PI / 2 : Math.PI * 3 / 2;
      } else if (y === 0) {
        angle = (x > 0) ? 0 : Math.PI;
      } else {
        angle = (x < 0) ? Math.atan(y / x) + Math.PI : (y < 0) ? Math.atan(y / x) + (2 * Math.PI) : Math.atan(y / x);
      }
      return (angle * 180 / Math.PI);
    }
  }
}