import mapStateTwoWay from '@/mixins/mapStateTwoWay'

export default {
  computed: {
    ...mapStateTwoWay([
      { n: 'App', k: 'showPages', m: 'setShowPages' },
      { n: 'Tableau', k: 'activePage', m: 'setActivePage' },
      { n: 'App', k: 'canvasAvailable', m: 'updateCanvasAvailable' },
    ])
  },
  methods: {
    addNewPage () {
      const pageId = this.canvasAvailable?.length + 1
      const newPage = this.createCanvasObject({
        overridId: pageId,
        overridCanvas: { objects: [] },
        overridImage: null,
        overridVideo: null,
        useDefaultGridProperties: true
      })
      this.$store.commit('App/setOrAddCanvasAvailable', newPage)
      const scroller = document.getElementById('pages-scroller')
      scroller.scrollTop = scroller.scrollHeight
      this.goToPage(pageId)
    },
    deletePage (page) {
      // Pour rediriger sur une page existante si on delete la page acutelle
      if (page.id === this.activePage) {
        this.goToPage(1)
      } else if (this.activePage === this.canvasAvailable.length) {
        this.goToPage(this.canvasAvailable.length - 1)
      }
      this.$store.commit('App/removeCanvasAvailable', page.id)
      this.$store.dispatch('App/saveCanvas', true)
    },
    goToPage (id) {
      if (id === undefined || id === null || id < 1 || id > this.canvasAvailable.length) {
        id = 1
      }
      this.$store.commit('App/updateCanvasAvailable', this.createCanvasObject())
      this.$store.dispatch('App/saveCanvas', true)
      this.$store.commit('App/setCanvasLoader', true)
      if (this.$store.state.App.windowSize.width < 1000) {
        this.showPages = false
      }
      this.activePage = id

      // Changing the URL
      const url = new URL(window.location);
      if (id === 1 || id === "1") {
        url.searchParams.delete('page')
      } else {
        url.searchParams.set('page', id)
      }
      window.history.pushState({}, '', url)
    }
  }
}
