import { apolloClientV2 } from '@/setup/apollo.js'

const state = {
  activeTool: {
    title: 'Déplacement',
    value: 'pan',
    icon: { type: 'fad', name: 'arrows-alt' },
    cursor: 'all-scroll',
    hoverCursor: 'grab',
    hideOptions: true
  },
  optionsBoundingBox: {
    borderColor: 'black',
    cornerColor: 'black',
    cornerSize: 8,
    transparentCorners: false,
    borderDashArray: [3, 3]
  },
  draw: {
    drawMode: false,
    brushColor: '#000000',
    lineWidth: 10,
    lineColor: false,
    shadowWidth: false,
    shadowColor: false,
    shadowOffset: false
  },
  math: {
    svgToInsert: null,
    baseValue: null,
    showMathPreview: false
  },
  showDesmosPreview: false,
  showAudioPreview: false,
  text: {
    fillColor: '#000000',
    fontSize: 24,
    fontSelected: 'Red Hat Text',
    fontWeight: 'normal',
    fontStyle: 'normal',
    textAlign: 'left',
    textBackgroundColor: '#ffffff',
    underline: false,
    linethrough: false
  },
  shape: {
    active: 'circle',
    strokeColor: null,
    fillColor: '#000000',
    strokeWidth: 10
  },
  colors: {
    strokeColor: null,
    fillColor: '#000000'
  },
  image: {
    urlToAdd: null,
    tacheInterval: null
  },
  arrow: {
    start: 'none',
    end: 'none',
    color: '#000000',
    size: 20
  },
  links: {
    targetIdToAdd: null,
    bowToAdd: null,
    targetToGoAfterPageChange: null
  },
  isDrawToolHoverShowPreview: false,
  coordToInsert: null,
  mathValueToInsert: null,
  resetEditMath: false,
  mathOldValue: null,
  desmosToInsert: {
    desmosSvg: null,
    desmosExpressions: []
  },
  audio: {
    recorder: null,
    audioWave: false,
    recorderToLire: null
  }
}

const actions = {
  startImageTache ({ state, commit }, tacheId) {
    // clear task in progress if there is one
    if (state.image.tacheInterval) {
      clearInterval(state.image.tacheInterval)
      state.image.tacheInterval = null
    }

    state.image.tacheInterval = setInterval(async () => {
      const { data } = await apolloClientV2.query({
        query: require('@/apollo/queries/communAsynchrone.gql'),
        variables: {
          id: tacheId
        },
        fetchPolicy: 'no-cache'
      })

      const res = data.communAsynchrone
      if (res?.statutCd === 2) {
        clearInterval(state.image.tacheInterval)
        state.image.tacheInterval = null
        commit('addImageFromUrl', res?.image?.origin)
      }
    }, 3000)
  },
  setFont ({ commit }, fontSelected) {
    commit('setFontSelected', fontSelected)
  }
}

const mutations = {
  setMathOldValue (state, payload) {
    state.mathOldValue = payload
  },
  setResetEditMath (state, payload) {
    state.resetEditMath = payload
  },
  insertMath (state, payload) {
    state.mathValueToInsert = payload
  },
  setDesmosSvg (state, payload) {
    state.desmosToInsert.desmosSvg = payload
  },
  setDesmosExpressions (state, payload) {
    state.desmosToInsert.desmosExpressions = payload
  },
  setCoordToInsert (state, payload) {
    state.coordToInsert = payload
  },
  setIsDrawToolHoverShowPreviewt (state, payload) {
    state.isDrawToolHoverShowPreview = payload
  },
  setGlobalStrokeColor (state, payload) {
    state.color.strokeColor = payload
  },
  setGlobalFillColor (state, payload) {
    state.color.fillColor = payload
  },
  setActiveShape (state, payload) {
    state.shape.active = payload
  },
  setShapeStrokeColor (state, payload) {
    state.shape.strokeColor = payload
  },
  setShapeStrokeWidth (state, payload) {
    state.shape.strokeWidth = payload
  },
  setShapeFillColor (state, payload) {
    state.shape.fillColor = payload
  },
  setFontSelected (state, payload) {
    state.text.fontSelected = payload
  },
  setFontSize (state, payload) {
    state.text.fontSize = payload
  },
  setTextBackgroundColor (state, payload) {
    state.text.textBackgroundColor = payload
  },
  setUnderLine (state, payload) {
    state.text.underline = payload
  },
  setLineThrough (state, payload) {
    state.text.linethrough = payload
  },
  setFontWeight (state, payload) {
    state.text.fontWeight = payload
  },
  setFontStyle (state, payload) {
    state.text.fontStyle = payload
  },
  setTextAlign (state, payload) {
    state.text.textAlign = payload
  },
  setMathBaseVal (state, payload) {
    state.math.baseValue = payload
  },
  setMathSvgToInsert (state, payload) {
    state.math.svgToInsert = payload
  },
  setShowMathPreview (state, payload) {
    state.math.showMathPreview = payload
  },
  setShowDesmosPreview (state, payload) {
    state.showDesmosPreview = payload
  },
  setShowAudioPreview (state, payload) {
    state.showAudioPreview = payload
  },
  setActiveTool (state, payload) {
    state.activeTool = payload
  },
  setDrawMode (state, payload) {
    state.draw.drawMode = payload
  },
  setBrushColor (state, payload) {
    state.draw.brushColor = payload
  },
  setLineWidth (state, payload) {
    state.draw.lineWidth = payload
  },
  setLineColor (state, payload) {
    state.draw.lineColor = payload
  },
  setShadowWidth (state, payload) {
    state.draw.shadowWidth = payload
  },
  setShadowColor (state, payload) {
    state.draw.shadowColor = payload
  },
  setShadowOffset (state, payload) {
    state.draw.shadowOffset = payload
  },
  addImageFromUrl (state, url) {
    state.image.urlToAdd = url
  },
  setArrowStart (state, payload) {
    state.arrow.start = payload
  },
  setArrowEnd (state, payload) {
    state.arrow.end = payload
  },
  setArrowColor (state, payload) {
    state.arrow.color = payload
  },
  addLinkTarget (state, id) {
    state.links.targetIdToAdd = id
  },
  addLinkBow (state, id) {
    state.links.bowToAdd = id
  },
  addTargetToDoAfterPageChange (state, targetId) {
    state.links.targetToGoAfterPageChange = targetId
  },
  setRecorder (state, payload) {
    state.audio.recorder = payload
  },
  setAudioWave (state, payload) {
    state.audio.audioWave = payload
  },
  setRecorderToLire (state, payload) {
    state.audio.recorderToLire = payload
  }
}

export default {
  namespaced: true,
  state,
  actions,
  mutations
}
