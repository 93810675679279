<script>
import { MathfieldElement } from "mathlive"
import 'mathlive/dist/mathlive-fonts.css'
import 'mathlive/dist/mathlive-static.css'
import toolsList from '@/mixins/toolsList'

export default {
  name: 'MathModal',
  mixins: [toolsList],
  data () {
    return {
      mathField: null
    }
  },
  computed: {
    showMathPreview: {
      get () {return this.$store.state.Tools.math.showMathPreview},
      set (val) {this.$store.commit('Tools/setShowMathPreview', val)}
    }
  },
  watch: {
    'showMathPreview' (newVal) {
      if (newVal) {
        setTimeout(() => {
          this.createMathElement()
        }, 200)
      }
    }
  },
  methods: {
    createMathElement () {
      this.mathField = new MathfieldElement()
      this.mathField.setOptions({
        virtualKeyboardMode: 'onfocus',
        locale: 'fr-CA'
      })
      document.getElementById('mathwrapper').appendChild(this.mathField)
      this.mathField.focus()
      if (this.$store.state.Tools.mathOldValue) {
        this.mathField.value = this.$store.state.Tools.mathOldValue
      }
    },
    toggleMath (resetEdit) {
      this.showMathPreview = !this.showMathPreview
      if (resetEdit) {
        this.$store.commit('Tools/setResetEditMath', true)
        if (this.$store.state.Tools.mathOldValue) {
          const temp = this.$store.state.Tools.mathOldValue
          this.$store.commit('Tools/setMathOldValue', null)
          this.$store.commit('Tools/insertMath', temp)
        }
      }
    },
    insertMathFunction () {
      this.$store.commit('Tools/setMathOldValue', null)
      this.$store.commit('Tools/insertMath', this.mathField.value)
      this.toggleMath()
      this.toggleActiveToolFromValue('select')
    }
  }
}
</script>

<template lang="pug">

.modal-index(v-if='showMathPreview')
  a.modal-overlay(@click='toggleMath', :class='{"visible-overlay" : showMathPreview}')
  .modal(style='margin-bottom: 270px;')
    perfect-scrollbar.modal-scroller(id='pages-scroller')
      .modal-header
        span Insérez votre fonction mathématique
        button.close-button(@click='toggleMath(true)')
          font-awesome-icon(:icon="['fas', 'times']", style='font-size: 24px')
      .modal-content__wrapper
        div(id='mathwrapper')
      .modal-actions__wrapper
        button(@click='insertMathFunction', style='padding: 20px; width:100%; font-size: 20px;') Confirmer et insérer
</template>

<style lang='sass'>
  @import 'src/assets/styles/base/_modal.sass'
</style>
