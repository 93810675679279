export default {
  computed: {
    zoomLevel () {
      return this.$store.state.Tableau.canvasZoom
    }
  },
  watch: {
    '$store.state.Tools.image.urlToAdd': {
      deep: true,
      handler (newVal) {
        if (newVal) {
          this.addImageFromUrl(newVal)
        }
      }
    }
  },
  methods: {
    addImageFromUrl (imageUrl) {
      this.$Fabric.Image.fromURL(imageUrl,
        (oImg) => {
          oImg.scale(this.getNewImgScaleBasedOnItsMeta(oImg))
          oImg.set(this.getNewImgPositionBasedOnItsMeta(oImg))
          this.fabriCanvas.add(oImg)
          this.toggleActiveToolFromValue('select')
        },
        { crossOrigin: 'Anonymous' }
      )
      this.$store.commit('Tools/addImageFromUrl', null)
    },
    getNewImgScaleBasedOnItsMeta ({ height, width }, maxProportion = 0.6) {
      // HTML Canvas Container
      const { clientHeight, clientWidth } = this.getHtmlContainerBoundingBox()

      const maxHeight = clientHeight * maxProportion / this.zoomLevel
      const maxWidth = clientWidth * maxProportion / this.zoomLevel

      return Number(Math.min(1, maxHeight / height, maxWidth / width).toFixed(2))
    },
    getNewImgPositionBasedOnItsMeta (img) {
      // Actual Position in canvas
      const x = this.$store.state.Tableau.canvasPosX
      const y = this.$store.state.Tableau.canvasPosY
      const { clientHeight, clientWidth } = this.getHtmlContainerBoundingBox()

      // HTML Canvas Container
      const imgWidth = img.width * img.scaleX
      const imgHeight = img.height * img.scaleY

      const canvasRenderWidth = clientWidth / this.zoomLevel
      const canvasRenderHeight = clientHeight / this.zoomLevel

      const left = - x + (canvasRenderWidth - imgWidth) / 2
      const top = - y + (canvasRenderHeight - imgHeight) / 2
      return { left, top }
    },
    getHtmlContainerBoundingBox () {
      const htmlContainer = this.$refs.tableaucontent
      const { clientHeight, clientWidth } = htmlContainer
      return { clientHeight, clientWidth }
    }
  }
}