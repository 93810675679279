export default {
  methods: {
    setEraserMode () {
      // TODO FORK LIBRARY TO IMPLEMENT https://github.com/fabricjs/fabric.js/issues/1225
    },
    deleteSelectedItem () {
      this.fabriCanvas.getActiveObjects().forEach(selection => {
        console.debug('remove6')
        this.fabriCanvas.remove(selection)
      })
      this.fabriCanvas.discardActiveObject()
    }
  }
}
