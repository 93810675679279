<script>
import VideoOptionsInput from '@/components/tableau/tools/videoOptionsInput'

export default {
  name: 'VideoOptions',
  components: { VideoOptionsInput },
  computed: {
    pages () {
      return this.$store.state.App.canvasAvailable
    }
  }
}
</script>

<template lang="pug">
.tool-index.video-options
  .tool-section
    section.tool-option.vertical(v-for="(page, index) in pages")
      label.tool-option--title Page {{ index + 1 }}
      VideoOptionsInput(:page="page" :index="index")
      
</template>

<style lang="sass" scoped>
.tool-index.video-options
  label
    margin: 6px 0
  input
    font-size: 16px
    padding: 2px 8px
    border-radius: 20px

</style>
