
<script>
export default {
  name: "voiceOptions",
  computed: {
  },
  }
</script>
<template lang="pug">
.tool-index.text-options
  .tool-section
    section.tool-option.vertical
      div Veuillez cliquer là où vous désirez insérer votre vocale.
</template>

<style lang="sass" scoped>

</style>