import Vue from 'vue'

import { library } from '@fortawesome/fontawesome-svg-core'
// import { fas } from '@fortawesome/free-solid-svg-icons'
import {
  faPencil,
  faWaveSine,
  faEraser,
  faFunction,
  faSquare,
  faImage,
  faSearchPlus,
  faSearchMinus,
  faSearch,
  faExpandArrowsAlt,
  faCompressArrowsAlt,
  faPlayCircle,
  faFilePlus,
  faTrashAlt,
  faArrowsAlt,
  faVideo,
  faSave,
  faCheck,
  faTimesCircle,
  faLongArrowAltUp,
  faBullseyeArrow,
  faEyeSlash,
  faTrash,
  faSendBackward,
  faBringForward,
  faLocation,
  faMicrophone
} from '@fortawesome/pro-duotone-svg-icons'
import {
  faTimes as fasTimes,
  faText as fasText,
  faHorizontalRule as fasHorizontalRule,
  faAngleUp as fasAngleUp,
  faAngleLeft as fasAngleLeft,
  faAngleRight as fasAngleRight,
  faEllipsisH as fasEllipsisH,
  faPlus as fasPlus,
  faGripVertical as fasGripVertical,
  faSpinner as faSpinner,
  faAngleDown as fasAngleDown,
  faQuestion as fasQuestion,
  faUndo as fasUndo,
  faRedo as fasRedo,
  faMousePointer as fasMousePointer,
  faBold as fasBold,
  faItalic as fasItalic,
  faStrikethrough as fasStrikethrough,
  faUnderline as fasUnderline,
  faCircle as fasCircle,
  faTriangle as fasTriangle,
  faRectangleLandscape as fasRectangleLandscape,
  faAlignLeft as fasAlignLeft,
  faAlignRight as fasAlignRight,
  faAlignCenter as fasAlignCenter,
  faHandPaper as fasHandPaper,
  faCaretLeft as fasCaretLeft,
  faCaretRight as fasCaretRight,
  faBowArrow as fasBowArrow,
  faBullseyeArrow as fasBullseyeArrow
} from '@fortawesome/pro-solid-svg-icons'

import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

library.add(
  faPencil,
  faWaveSine,
  faEraser,
  faFunction,
  faSquare,
  faImage,
  faSearchPlus,
  faSearchMinus,
  faSearch,
  faExpandArrowsAlt,
  faCompressArrowsAlt,
  faPlayCircle,
  faFilePlus,
  faTrashAlt,
  faArrowsAlt,
  faVideo,
  faSave,
  faCheck,
  faTimesCircle,
  faLongArrowAltUp,
  faBullseyeArrow,
  faEyeSlash,
  faTrash,
  faLocation,
  // Solid
  faSpinner,
  fasTimes,
  fasText,
  fasHorizontalRule,
  fasAngleUp,
  fasEllipsisH,
  fasPlus,
  fasGripVertical,
  fasAngleDown,
  fasAngleLeft,
  fasAngleRight,
  fasQuestion,
  fasUndo,
  fasRedo,
  fasMousePointer,
  fasBold,
  fasItalic,
  fasStrikethrough,
  fasUnderline,
  fasCircle,
  fasTriangle,
  fasRectangleLandscape,
  fasAlignLeft,
  fasAlignRight,
  fasAlignCenter,
  fasHandPaper,
  fasCaretLeft,
  fasCaretRight,
  fasBowArrow,
  fasBullseyeArrow,
  faSendBackward,
  faBringForward,
  faMicrophone
)

Vue.component('font-awesome-icon', FontAwesomeIcon)
