<script>
import loadingIndicator from './loadingIndicator.vue'
import zoomIndicator from './zoomIndicator.vue'
import positionIndicator from './positionIndicator.vue'
import resolutionIndicator from './resolutionIndicator.vue'

export default {
  name: 'StatusBar',
  components: { loadingIndicator, zoomIndicator, positionIndicator, resolutionIndicator }
}
</script>

<template lang="pug">
.status-bar(v-if='!$store.state.App.viewerMode')
  loading-indicator
  zoom-indicator
  resolution-indicator
  position-indicator
</template>

<style lang="sass" scoped>
.status-bar
  z-index: 10
  display: flex
  position: absolute
  bottom: 20px
  left: 20px
  min-height: 20px
  min-width: fit-content
  background-color: #fff
  border-radius: 12px
  font-size: 12px
  line-height: 12px
  padding: 2px 12px
  gap: 10px
  cursor: default
  box-shadow: 2px 2px 5px rgba(0,0,0,0.3)
  > div
    display: flex
    align-items: center
    justify-content: center
    height: inherit
</style>
