<script>
import mapStateTwoWay from '@/mixins/mapStateTwoWay'

export default {
  name: 'drawOptionPreview',
  computed: {
    ...mapStateTwoWay([
      { n: 'Tools', k: 'isDrawToolHoverShowPreview', m: 'setIsDrawToolHoverShowPreviewt' }
    ]),
    previewStyle () {
      const width = this.$store.state.Tools.draw.lineWidth
      const color = this.$store.state.Tools.draw.brushColor
      const zoom = this.$store.state.Tableau.canvasZoom
      return {
        width: `${width * zoom}px`,
        height: `${width * zoom}px`,
        borderRadius: `${width * zoom}px`,
        backgroundColor: `${color}`
      }
    },
  }
}
</script>

<template lang="pug">
transition(name="pop")
  .size-preview-container(v-show="isDrawToolHoverShowPreview")
    .size-preview-circle(:style="previewStyle")
</template>

<style lang="sass" scoped>
.size-preview-container
  position: absolute
  top: 135px
  transform: translateY(-50%)
  left: 265px
  .size-preview-circle
    border: 2px solid black
    opacity: 0.5
    // default style overrided by the computed
    height: 20px
    width: 20px
    border-radius: 20px
</style>