<script>
import mapStateTwoWay from '@/mixins/mapStateTwoWay'

export default {
  name: 'Header',
  props: {
  },
  methods: {
  },
  mounted () {
    setTimeout(() => {
      this.showNotification = false
    }, this.notificationContent.timeout ? this.notificationContent.timeout : 4000)
  },
  computed: {
    ...mapStateTwoWay([
      { n: 'App', k: 'showNotification', m: 'setShowNotification' },
      { n: 'App', k: 'notificationContent', m: 'setNotificationContent' },
    ]),
  },
}
</script>

<template lang="pug">
.notification(:class='notificationContent.type ? notificationContent.type : null')
  template(v-if='notificationContent')
    span {{notificationContent.content}}
  template(v-else)
    span Pas de contenu, désolé
</template>

<style lang='sass'>
  @import 'src/assets/styles/layout/_notification.sass'

</style>
