export default {
  methods: {
    checkDoubleKEy (e) {
      return e.metaKey || e.altKey || e.ctrlKey || e.shiftKey
    }
  },
  mounted () {
    // Keyboard shortcurts - Might be move in another file or mixin
    document.addEventListener("keydown", (e) => {
      const isNoInputFocus = document.activeElement.tagName === "BODY" || document.activeElement.tagName === "BUTTON"
      if (!isNoInputFocus || this.$store.state.App.viewerMode) {
        return
      }

      if (e.code === "Delete") {
        this.deleteSelectedItem()
      }
      if (this.checkDoubleKEy(e)) {
        // Cancel the shortcut if Ctrl, Options/Alt, or Shift key is also pressed
        return null
      }

      if (e.code === "KeyH") {
        this.toggleActiveToolFromValue('pan')
      }
      if (e.code === "KeyV") {
        this.toggleActiveToolFromValue('select')
      }
      if (e.code === "KeyB") {
        this.toggleActiveToolFromValue('draw')
      }
      if (e.code === "KeyE") {
        this.toggleActiveToolFromValue('erase')
      }
      if (e.code === "KeyT") {
        this.toggleActiveToolFromValue('text')
      }
      if (e.code === "KeyM") {
        this.toggleActiveToolFromValue('math')
      }
      if (e.code === "KeyR") {
        this.toggleActiveToolFromValue('shapes')
      }
      if (e.code === "KeyY") {
        this.toggleActiveToolFromValue('video')
      }
      if (e.code === "KeyI") {
        this.toggleActiveToolFromValue('image')
      }
      if (e.code === "KeyL") {
        this.toggleActiveToolFromValue('links')
      }
      if (e.code === "KeyA") {
        this.toggleActiveToolFromValue('audio')
      }
      if (e.code === "KeyD") {
        this.toggleActiveToolFromValue('desmos')
      }
      if (e.code === "KeyU") {
        this.toggleActiveToolFromValue('arrow')
      }
    })
  }
}