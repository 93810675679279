<script>
import TextOptions from '@/components/tableau/tools/textOptions.vue'
import DrawOptions from '@/components/tableau/tools/drawOptions.vue'
import MathOptions from '@/components/tableau/tools/mathOptions.vue'
import ShapeOptions from '@/components/tableau/tools/shapeOptions.vue'
import DeleteOptions from '@/components/tableau/tools/deleteOptions.vue'
import VideoOptions from '@/components/tableau/tools/videoOptions.vue'
import ArrowOptions from '@/components/tableau/tools/arrowOptions.vue'
import DesmosOptions from '@/components/tableau/tools/desmosOptions.vue'
import VoiceOptions from '@/components/tableau/tools/voiceOptions.vue'
import mapStateTwoWay from '@/mixins/mapStateTwoWay'


export default {
  name: 'ToolProperties',
  components: {
    TextOptions,
    DrawOptions,
    MathOptions,
    ShapeOptions,
    DeleteOptions,
    VideoOptions,
    ArrowOptions,
    DesmosOptions,
    VoiceOptions
  },
  props: {
  },
  data () {
    return {
      expandedMenu: true
    }
  },
  watch: {
    activeTool (newVal) {
      if (newVal) {
        if (newVal.hideOptions) {
          this.expandedMenu = false
        } else {
          this.expandedMenu = true
        }
      }
    }
  },
  computed: {
    ...mapStateTwoWay([
      { n: 'Tools', k: 'activeTool', m: 'setActiveTool' },
    ])
  },
  methods: {
    toggleMenu () {
      this.expandedMenu = !this.expandedMenu
    }
  }
}
</script>

<template lang="pug">
.tool-properties-index(v-if='activeTool && !activeTool.hideOptions', :class='{"expanded-menu" : expandedMenu}')
  .tool-header
    .tool-icon
      font-awesome-icon(:icon="[activeTool.icon.type, activeTool.icon.name]", style='font-size: 16px')
    div
      div.tool-subtitle Outil actif
      div.tool-title {{activeTool.title}}
    button.expand-icon(@click='toggleMenu', v-if='!activeTool.hideOptions')
      font-awesome-icon(:icon="['fas', 'angle-up']", style='font-size: 16px')
  template(v-if='activeTool')
    text-options(v-if='activeTool.value === "text"')
    draw-options(v-else-if='activeTool.value === "draw"')
    delete-options(v-else-if='activeTool.value === "erase"')
    math-options(v-else-if='activeTool.value === "math"')
    shape-options(v-else-if='activeTool.value === "shapes"')
    video-options(v-else-if='activeTool.value === "video"')
    arrow-options(v-else-if='activeTool.value === "arrow"')
    desmos-options(v-else-if='activeTool.value === "desmos"')
    voice-options(v-else-if='activeTool.value === "audio"')
  div(v-else, style='padding: 5px') Options pour {{activeTool.title}} à venir
    pre debug {{activeTool}}
</template>

<style lang='sass'>
  @import 'src/assets/styles/components/tableau/_toolProperties.sass'

</style>
