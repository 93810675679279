<script>
export default {
  name: 'LoadingIndicator',
  computed: {
    isLoading () {
      return this.$store.state.Tableau.loadingIndicator
    }
  }
}
</script>

<template lang="pug">
.loading-indicator-container
  .loading(v-if="isLoading" v-tooltip.top-start="{ content: 'Chargement en cours...', offset: '5'}")
    font-awesome-icon.fa-spin(:icon="['fas', 'spinner']")
  .not-loading(v-else v-tooltip.top-start="{ content: 'Aucun chargement en cours', offset: '5'}")
    font-awesome-icon(:icon="['fad', 'check']")
</template>

<style lang="sass" scoped>
.loading-indicator-container
  width: fit-content
</style>