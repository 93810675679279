<script>
// import MathModal from '@/components/shared/mathModal.vue'
import Notification from '@/components/layout/notification.vue'
import ToolProperties from '@/components/tableau/toolProperties.vue'
import ImagePopin from '@/components/tableau/Images/ImagePopin.vue'
import LinkPopin from '@/components/tableau/Links/LinkPopin.vue'
import DrawOptionPreview from '@/components/tableau/tools/drawOptionPreview.vue'
import Controls from '@/components/tableau/controls.vue'
import Administrator from '@/components/tableau/administrator.vue'
import VideoPlayer from '@/components/tableau/videoPlayer.vue'
import StatusBar from '@/components/tableau/statusbar/statusBar.vue'
import Unauthorized from '@/components/tableau/unauthorized.vue'
import Draw from '@/mixins/draw'
import Eraser from '@/mixins/eraser'
import Text from '@/mixins/text'
import Image from '@/mixins/image'
import MathMixin from '@/mixins/math'
import Desmos from '@/mixins/desmos'
import Shape from '@/mixins/shape'
import Arrow from '@/mixins/arrow'
import Links from '@/mixins/links'
import TableauControls from '@/mixins/tableauControls'
import mapStateTwoWay from '@/mixins/mapStateTwoWay'
import toolsList from '@/mixins/toolsList'
import TableauShortcuts from '@/mixins/TableauShortcuts'
import TableauPages from '@/mixins/TableauPages'
import Audio from '@/mixins/audio.js'
import CanvasLoader from './shared/canvasLoader.vue'

const throttle = require('lodash.throttle')

export default {
  name: 'TableauIndex',
  mixins: [Draw, Eraser, Text, Image, MathMixin, Shape, TableauControls, toolsList, TableauShortcuts, Links, TableauPages, Arrow, Desmos, Audio],
  components: {
    // MathModal,
    Notification,
    ToolProperties,
    DrawOptionPreview,
    Controls,
    Administrator,
    VideoPlayer,
    StatusBar,
    ImagePopin,
    LinkPopin,
    Unauthorized,
    CanvasLoader
},
  computed: {
    ...mapStateTwoWay([
      { n: 'Tools', k: 'activeTool', m: 'setActiveTool' },
      { n: 'App', k: 'showNotification', m: 'setShowNotification' },
      { n: 'App', k: 'showPages', m: 'setShowPages' },
      { n: 'App', k: 'canvasAvailable', m: 'updateCanvasAvailable' },
      { n: 'Tableau', k: 'activeGrid', m: 'setActiveGrid' },
      { n: 'Tableau', k: 'activePage', m: 'setActivePage' },
    ]),
    fabriCanvas: {
      get () { return this.$store.state.App.activeCanvas },
      set (val) { this.$store.commit('App/setActiveCanvas', val) }
    },
    showMathPreview: {
      get () {return this.$store.state.Tools.math.showMathPreview},
      set (val) {this.$store.commit('Tools/setShowMathPreview', val)}
    }
  },
  data () {
    return {
      // fabriCanvas: null,
      fontLoadedAlreadyTriggered: false,
      selectedText: false,
      panMode: false,
      panAvailable: false,
      panX: 0,
      panY: 0,
      eraseMode: false,
      editMath: false,
      drawingShape: false,
      lastRelativePanValues: { x: 0, y: 0 },
      beforeLastRelativePanValues: { x: 0, y: 0 }
    }
  },
  watch: {
    '$store.state.Tools.resetEditMath' (newVal) {
      if (newVal) {
        this.editMath = false
        this.$store.commit('Tools/setResetEditMath', false)
      }
    },
    '$store.state.App.finishInit' (newVal) {
      if (newVal) {
        const temp = new URLSearchParams(window.location.search)
        const page = temp.get('page') ? temp.get('page') - 1 : 0
        this.$store.commit('Tableau/setActivePage', page + 1)
        if (this.canvasAvailable && this.canvasAvailable[page]?.data) {
          this.fabriCanvas.loadFromJSON(this.canvasAvailable[page].data)
          this.editZoomPos(this.canvasAvailable[page])
          this.checkUser()
        }
      }
    },
    '$store.state.Tableau.activePage' (newVal) {
      this.onPageChange(newVal)
    },
    'fabriCanvas._objects' (canvasObjects) {
      if (this.$store.state.App.viewerMode) {
        if (canvasObjects.length > 0) {
          canvasObjects.forEach(element => {
            element.selectable = false
          })
        }
      }
    },
    '$store.state.Tools.activeTool': {
      deep: true,
      handler (newVal) {
        this.onActiveToolChange(newVal)
      }
    },
    '$store.state.App.isCanvasReadyToBeShow': {
      // Triggered when app loaded and everytime the window is resized
      deep: true,
      handler (newVal) {
        if (newVal) {
          this.createCanvas()
        }
      }
    }
  },
  methods: {
    changeSelectableStatus (val) {
      const temp = this.fabriCanvas.getObjects()
      if (temp.length > 0) {
        // console.log('ON CHANGE SELECTABLE', this.viewerMode)
        temp.forEach(element => {
          element.selectable = val
        })
      }
      if (!val) {
        this.fabriCanvas.discardActiveObject()
      }
    },
    checkSelection (selection, windowCoord, canvasCoord) {
      this.$store.commit('Tableau/setSelection', null)
      const custom = selection.custom
      // si on est en mode math et qu'on sélectionne un truc de math
      if (custom && custom.type === 'math' && this.activeTool && this.activeTool.value === 'math') {
        this.editMath = true
        console.debug('remove1')
        this.fabriCanvas.remove(selection)
        this.setMathField(windowCoord, canvasCoord, custom.mathSvgString)
      } else if (custom && custom.type === 'text' && this.activeTool && this.activeTool.value === 'text') {
        this.selectedText = true
        this.$store.commit('Tableau/setSelection', selection)
        selection.enterEditing()
        this.fabriCanvas.setActiveObject(selection)
        // this
        // pour evité de recréer un élem de text
        setTimeout(() => {
          this.selectedText = false
        }, 500)
      //  FAUT FAIRE UN CAS AU CAS OU ON VEUT EDITER NOTRE SHAPE

      } else if (custom && custom.type === 'shape' && this.activeTool && this.activeTool.value === 'shapes') {
        this.$store.commit('Tableau/setSelection', selection)
        this.fabriCanvas.setActiveObject(selection)
        this.drawingShape = true
      }else if (selection && selection.type === 'group' && this.activeTool && this.activeTool.value === 'arrow') {
        this.$store.commit('Tableau/setSelection', selection)
        this.fabriCanvas.setActiveObject(selection)
      } else if (selection && selection.type === 'desmos' && this.activeTool && this.activeTool.value === 'desmos') {
        this.$store.commit('Tableau/setSelection', selection)
        this.fabriCanvas.setActiveObject(selection)
      } else if (this.eraseMode) {
        // If the user tries to remove a target, check if bow are pointing to this target before deletion
        const tid = selection?.targetid
        if (tid) {
          // Timeout is needed because "confirm" native method will stop javascript execution until user answers
          // Causing the "mouseup" and "mouseclick" events to not get triggered
          setTimeout(() => {
            const arcsPointingCount = this.$store.getters['App/pagesObjects'].filter(object => {
              return object?.totarget === tid
            }).length
            if (arcsPointingCount !== 0) {
              // Ask for confirmation, then delete also all the bows
              const msg = `Suppriner la cible "${tid}"" entrainera la suppression de tous les arcs pointant sur cette cible. Voulez vous continuer et supprimer la cible ?`
              if (confirm(msg)) {
                // Delete all
                this.fabriCanvas.getObjects().forEach(obj => {
                  if (obj.targetid === tid || obj.totarget === tid) {
                    console.debug('remove2')
                    this.fabriCanvas.remove(obj)
                  }
                })
              }
              return 
            } else {
              console.debug('remove3')
              this.fabriCanvas.remove(selection)
            }
          }, 350)
        } else {
          console.debug('remove4')
          this.fabriCanvas.remove(selection)
        }
      }
    },
    zoomCanvas (isZoomIn) {
      let zoom = this.fabriCanvas.getZoom()
      const delta = 0.15
      if (isZoomIn) {
        zoom *= 1 + delta
      } else {
        zoom *= 1 / (1 + delta)
      }
      if (zoom > 20) zoom = 20
      if (zoom < 0.01) zoom = 0.01
      const canvasWrapper = document.querySelector('.tableau-canvas__wrapper')
      const xcoord = canvasWrapper.offsetWidth / 2
      const ycoord = canvasWrapper.offsetHeight / 2
      this.fabriCanvas.zoomToPoint(new this.$Fabric.Point(xcoord, ycoord), zoom)
    },
    recenter () {
      this.fabriCanvas.absolutePan({ x: 0, y: 0 })
      this.fabriCanvas.setZoom(1)
    },
    updateStoreCanvas: throttle( function () {
      this.$store.commit('App/updateCanvasAvailable', this.createCanvasObject())
      this.$store.dispatch('App/saveCanvas')
      this.$store.commit('App/setCanvasLoader', false)
    }, 3000, { leading: false, trailing: true }),
    changeDefaultCursor (cursorType) {
      this.fabriCanvas.defaultCursor = cursorType ? cursorType : "default"
    },
    changerUpperCanvasCursor (cursorType) {
      if (this.fabriCanvas.upperCanvasEl.style.cursor !== cursorType) {
        this.fabriCanvas.upperCanvasEl.style.cursor = cursorType ? cursorType : "default"
      }
    },
    checkUser () {
      if (!this.$store.state.User.profile || (this.$store.state.User.profile && this.$store.state.App.dataRef && this.$store.state.App.dataRef.proprietaire && this.$store.state.App.dataRef.proprietaire.id !== this.$store.state.User.profile.id)) {
        const canvas = this.fabriCanvas
        const temp = canvas.getObjects()
        if (temp.length > 0) {
          temp.forEach(element => {
            element.selectable = false
          })
        }
      } 
    },
    createCanvas () {
      this.fabriCanvas = new this.$Fabric.Canvas('refCanvas', {
        id: 'canvasFabric',
        enableRetinaScaling: false,
        class: 'width-height',
        fireRightClick: true,
        stopContextMenu: true,
        selectionColor: 'rgba(0, 94, 255, 0.25)',
        selectionBorderColor: 'rgba(0,0,0,0)',
        preserveObjectStacking: true
      })
      if (this.$store.state.App.dataRef) {
        const activePage = this.$store.state.Tableau?.activePage ?? 1
        this.fabriCanvas.loadFromJSON(this.canvasAvailable[activePage - 1].data)
        this.onCanvasCreated()
        this.editZoomPos(this.canvasAvailable[activePage - 1])
        this.fabriCanvas.renderAll()
        this.checkUser()
      } else {
        this.$store.commit('App/setOrAddCanvasAvailable', this.createCanvasObject({ overridId: 1, overridImage: null }))
      }
      this.attachEvents()
      setTimeout(() => {
        this.fixMathFormulaTooSmall()
      }, 500)
    },
    backAndForward(val) {
      var objectToSend = this.$store.state.Tableau.elementSelected
      if (val) {
        this.fabriCanvas.sendToBack(objectToSend);
      } else {
        this.fabriCanvas.bringToFront(objectToSend);
      }
    }
  },
  mounted () {
    // As of March 2023, fonts are now preloaded with the 'webfontloader' library, developed by Google
    // We do not need to worry about it anymore. We can consider that all fonts are already loaded before the Mounted event
    this.createCanvas()
  }
}
</script>

<template lang="pug">
.tableau-index(:class='{"viewer-mode" : $store.state.App.viewerMode, "active-menu" : ($store.state.App.showPages && !$store.state.App.mobileTemplate)}')
  .tableau-content(ref="tableaucontent")
    canvas-loader(v-if='$store.state.App.canvasLoader')
    administrator
    transition(name='custom-classes-transition', enter-active-class='animate__animated animate__fadeInUp animate__faster', leave-active-class='animate__animated animate__fadeOutDown animate__faster', mode='out-in')
      notification(v-if='showNotification')
    transition(name='custom-classes-transition', enter-active-class='animate__animated animate__fadeIn animate__faster', leave-active-class='animate__animated animate__fadeOut animate__faster', mode='out-in')
      tool-properties(v-if='activeTool && !$store.state.App.viewerMode')
    draw-option-preview
    status-bar
    controls(@zoomIn='zoomCanvas(true)', @zoomOut='zoomCanvas()', @toBack='backAndForward(true)', @toFront='backAndForward()', @recenter='recenter')
    video-player
    unauthorized
    transition(name="popin")
      image-popin(v-if="activeTool.value === 'image'")
    transition(name="popin")
      link-popin(v-if="activeTool.value === 'links'")
    div.tableau-canvas__wrapper(v-if="$store.state.App.isCanvasReadyToBeShow")
      canvas(id='refCanvas' :width="$store.state.App.canvasWidth" :height="$store.state.App.canvasHeight")
    .tableau-grid(v-if='activeGrid', :class='activeGrid.class')
</template>

<style lang='sass'>
@import 'src/assets/styles/components/tableau/_index.sass'
</style>
