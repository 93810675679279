<script>
import ImagePopinImgPreview from './ImagePopinImgPreview.vue'

export default {
  name: 'ImagePopinGallery',
  components: { ImagePopinImgPreview },
  data () {
    return {
      imagesTotalCount: 0,
      imagesGallery: [],
      filteredGallery: [],
      fetchingImages: false,
      selectedImage: {},
      sendingImageToCanvas: false,
      currentPage: 1
    }
  },
  apollo: {
    images: {
      query: require('@/apollo/queries/communImages.gql'),
      variables () {
        return {
          limit: 20,
          page: this.currentPage
        }
      },
      update ({ communImages }) {
        this.imagesTotalCount = communImages.total
        this.imagesGallery = communImages.resultat
        this.updateFilteredGallery()
      },
      fetchPolicy: 'cache-and-network',
      watchLoading (isLoading) {
        this.fetchingImages = isLoading
      }
    }
  },
  computed: {
    btnLabel () {
      return this.sendingImageToCanvas ? 'Chargement...' : "Insérer l'image selectionnée"
    },
  },
  methods: {
    select (img) {
      if (this.selectedImage?.id === img.id) {
        this.selectedImage = {}
      } else {
        this.selectedImage = img
      }
    },
    submitImage () {
      this.$store.commit('Tools/addImageFromUrl', this.selectedImage.origin)
      this.sendingImageToCanvas = true
    },
    updateFilteredGallery () {
      const hideThoseIds = this.$cookies.get('HideImagesFromGallery')?.l ?? []
      this.filteredGallery = this.imagesGallery.filter(image => hideThoseIds.findIndex(im => im === image.id) === -1)
    }
  }
}
</script>

<template lang="pug">
.popin-gallery
  h3 Mes images {{ imagesTotalCount !== null ? `(${imagesTotalCount})` : ''}}

  h5.no-image-found(v-if="imagesTotalCount === 0 || filteredGallery.length === 0") Aucune image n'est présente sur votre compte. 
    <br>Pour en ajouter une, cliquez sur "Téléverser une image" ci-contre.

  .popin-gallery-list-scroller
    .popin-gallery-list
      image-popin-img-preview(
        v-for="img in filteredGallery"
        :key="img.id"
        :img="img"
        :selected="selectedImage.id === img.id"
        @click="select(img)"
        @refresh="updateFilteredGallery"
      )
  div.pagination-wrapper
    template(v-for="page in Math.ceil(imagesTotalCount / 18)")
      button.page-btn(@click='currentPage = page' :class='{ actif: page === currentPage}') {{page}}

  button.selection-btn(:disabled="!selectedImage.origin || sendingImageToCanvas" @click="submitImage") {{ this.btnLabel }}
</template>

<style lang="sass" scoped>
.popin-gallery
  height: 100% 
  padding: 0 40px 0 15px
  position: relative
  min-width: calc(100% - 360px)
  @media (max-width: $small)
    padding: 0
  .no-image-found
    font-size: 16px
    line-height: 24px
    font-style: italic
    color: grey
  .popin-gallery-list-scroller
    max-height: calc(100% - 82px - 110px)
    height: 100%
    overflow: auto
    @media (max-height: $small)
      max-height: 80%
      border: 1px dashed black
      padding: 20px
    .popin-gallery-list
      display: flex
      flex-wrap: wrap
      justify-content: flex-start
      gap: 14px 18px
      @media (max-width: $small)
        border: 1px dashed black
        padding: 20px 0 20px 20px
  .pagination-wrapper
    display: flex
    justify-content: center
    .page-btn
      background-color: white
      margin: 28px 6px 0px
      width: 32px
      height: 32px
      border-radius: 20px
      box-shadow: 0px 2px #D2D2D2
      display: flex
      justify-content: center
      align-items: center
    .actif
      background-color: #a1cee6
      color: white

  .selection-btn
    position: absolute
    bottom: 0
    padding: 8px 12px
    background-color: #a1cee6
    color: white
    border-radius: 12px
    width: calc(100% - 40px - 15px)
    @media (max-width: $small)
      bottom: -20px
      left: 20px
    @media (max-height: $small)
      position: relative
      margin: 20px 0
      height: fit-content
      width: fit-content
</style>