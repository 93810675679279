<template lang="pug">
.miniature-data
  .miniature-grid-bg(:style="backgroundStyle")
  img(v-if='miniature', :src='miniature')
</template>

<script>
export default {
  name: 'MiniatureData',
  props: {
    data: { required: true, type: Object }
  },
  computed: {
    backgroundStyle () {
      return {
        backgroundColor: this.data.gridProperties.tableauBgColor
      }
    },
    miniature () {
      const storedJson = this.$store.state.App.canvasAvailable.find(e => e.id === this.data.id)
      return storedJson ? storedJson.image : null
    }
  }
}
</script>

<style lang='sass'>
.miniature-data
  position: relative
  width: 100%
  height: 100%
  img
    position: relative
    width: 100%
    height: 100%
    object-fit: contain
    object-position: center
    z-index: 10
  .miniature-grid-bg
    position: absolute
    top: 0
    left: 0
    width: 100%
    height: 100%
    background-color: red
    opacity: 0.85
</style>
