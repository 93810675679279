export default {
  computed: {
    mobileTemplate: {
      get() { return this.$store.state.App.mobileTemplate },
      set(val) { this.$store.commit('App/setMobileTemplate', val) }
    },
    canvasWidth: {
      get() { return this.$store.state.App.canvasWidth },
      set(val) { this.$store.commit('App/setCanvasWidth', val) }
    },
    canvasHeight: {
      get() { return this.$store.state.App.canvasHeight },
      set(val) { this.$store.commit('App/setCanvasHeight', val) }
    },
    windowSize: {
      get() { return this.$store.state.App.windowSize },
      set(val) { this.$store.commit('App/setWindowSize', val) }
    },
  },
  mounted() {
    window.addEventListener('resize', this.handleResize)
    this.handleResize()
  },
  methods: {
    handleResize() {
      this.$store.commit('App/setisCanvasReadyToBeShow', false)
      if (window.screen.width < window.screen.height) {
        this.canvasWidth = window.screen.height
        this.canvasHeight = window.screen.height
      } else {
        this.canvasWidth = window.screen.width
        this.canvasHeight = window.screen.height
      }
      this.$store.commit('App/setisCanvasReadyToBeShow', true)

      if (this.windowSize.width !== window.innerWidth || this.windowSize.height !== window.innerHeight) {
        this.windowSize.width = window.innerWidth
        this.windowSize.height = window.innerHeight
        if (this.windowSize.width < 1601) {
          this.notificationsSidebar = false
        }
        if (this.windowSize.width < 1201) {
          this.mobileTemplate = true
        } else {
          this.mobileTemplate = false
        }
        let vh = window.innerHeight * 0.01
        document.documentElement.style.setProperty('--vh', `${vh}px`)
      }
    },
  }
}
